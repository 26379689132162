import { api } from '../../api/axios'

export default new (class StickerApi {
  async Favorite(data) {
    try {
      return api.post(`stickers/`, data)
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async FindAll() {
    try {
      return api.get(`stickers/`)
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async Delete(id) {
    try {
      return api.delete(`stickers/${id}`)
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
