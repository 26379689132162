export default {
  colors: {
    Separator: '#0B1E3A',
    SeparatorOnFundoAzul: '#000C1E',
    SubText: '#B5B5B6',
    Primary: '#444',
    BackgroundMessage: '#333',
    BackgroundMessageStrong: '#7863DE',
    BackgroundMessageStrongOverflow: '#504394',
    Outline: '#f0f0f0',
    Text: '#fff',
    TextHover: '#444',
    Alert: '#ff5f5f',
    Success: '#4CAF50',
    Warning: '#ffcc00',
    Jit: '#5000ff',
    JitHover: '#4d00e6',
    TextOnJit: '#fff',
    White: '#fff',
    OnPrimary: '#242424',
    DividerContact: '#111',
    WhiteHover: '#181818',
    MainScreen: '#333',
    Popover: '#444',
    PopoverHover: '#333',
    ImageBackground: '#444',
    Track: '#444',
    Thumb: '#999',
    Tooltip: '#242424',
    SelectedContact: '#afa2eb',
    Note: 'orange',
    Button: '#afa2eb',
    guto: {
      Azul1: '#0065FF',
      FundoAzul: '#10223B',
      BaseAzul1: '#172E4F',
      BaseAzul2: '#233856',
      Azul2: '#294265',
      Azul3: '#2D486D',
      Verde: '#33D24D',
      Azul4: '#10BBFF',
      Vermelho: '#EA3B3B',
      Laranja: '#F57124',
      FundoLogin: '#172E4F',
      BotaoLogin: '#0065FF',
      BackgroundLogin: '#10223B',
      BorderInput: '#10223B',
    },
  },
}
