import UserApi from './api/UserApi'

class UserService {
  async getUsers() {
    try {
      const response = await UserApi.getUsers()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserByIdContact(id) {
    try {
      const response = await UserApi.getUserByIdContact(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createUser(data) {
    try {
      const response = await UserApi.createUser(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserById(id) {
    try {
      const response = await UserApi.getUserById(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateUser(id, data) {
    try {
      console.log(data)
      console.log(id)
      const response = await UserApi.updateUser(id, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteUser(id) {
    try {
      const response = await UserApi.deleteUser(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateImage(id, file) {
    try {
      const response = await UserApi.updateImage(id, file)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAudios() {
    try {
      const response = await UserApi.getAudios()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteAudio(id) {
    try {
      await UserApi.deleteAudios(id)
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async storeAudio(base64, label) {
    try {
      const response = UserApi.storeAudios(base64, label)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateStatusUser(id, data) {
    try {
      const response = await UserApi.updateStatusUser(id, data)
      return response
    } catch (error) {
      return error
    }
  }
}

export default new UserService()
