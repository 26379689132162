import { useCallback, useEffect, useState, useRef } from 'react'
import {
  Container,
  Header,
  Title,
  Subtitle,
  ListContacts,
  Contact,
  ContactText,
  DivRowContact,
  DivButton,
  ButtonCancel,
  ButtonCadastro,
  NoContacts,
  XCircleStyled,
} from './styles.js'
import { NameEmailSection, Email } from '../../styles.js'
import Input from '../../../../../../../../../../shared/components/Input/index.jsx'
import ContactService from '../../../../../../../../../../shared/services/Contact/ContactService.js'
import UserService from '../../../../../../../../../../shared/services/User/UserService.js'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../../../shared/components/Dialog/index.jsx'
import SoftDelete from '../../../../../../../../../../shared/components/SoftDelete'
const DialogContacts = ({
  id,
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  usersSelecteds,
  setUsersSelecteds,
}) => {
  const [searchContact, setSearchContact] = useState('')
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const isRevertingRef = useRef(false)
  const timeoutRef = useRef(null)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleSelectUser = (user) => {
    const index = selectedUsers.findIndex(
      (selectedUser) => selectedUser.id_user === user.id_user,
    )
    if (index === -1) {
      setSelectedUsers([...selectedUsers, user])
    } else {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedUser) => selectedUser.id_user !== user.id_user,
        ),
      )
    }
  }

  const handleCloseDialog = () => {
    onChangeOpen(false)
    setSelectedUsers([])
  }

  const filteredContactsByNameOrNumber = () => {
    if (searchContact) {
      return users.filter((user) => {
        const email = user?.email_user?.toLowerCase()
        const name = user?.full_name_user?.toLowerCase()
        const search = searchContact.toLowerCase()
        if (users.length === 0) return false
        return name.includes(search) || email.includes(search)
      })
    } else {
      return users
    }
  }

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUsers()
      if (response.data) {
        setUsers(
          response.data.filter(
            (user) =>
              !usersSelecteds.find(
                (userSelected) => userSelected.id_user === user.id_user,
              ),
          ),
        )
      }
    }
    getUsers()
  }, [usersSelecteds])

  const verifySelectedUser = useCallback(
    (user) => {
      return (
        selectedUsers.findIndex(
          (selectedUser) => selectedUser.id_user === user.id_user,
        ) !== -1
      )
    },
    [selectedUsers],
  )

  const handleRevert = (previousSelectedUsers) => {
    isRevertingRef.current = true
    clearTimeout(timeoutRef.current)
    setSelectedUsers(previousSelectedUsers)
    toast.dismiss()
    toast.success('Atribuição de usuários revertida.')
  }

  const handleSave = async () => {
    const previousSelectedUsers = [...selectedUsers]
    setUsersSelecteds([...usersSelecteds, ...selectedUsers])
    onChangeOpen(false)
    toast.info(
      <SoftDelete
        revert={() => {
          handleRevert(previousSelectedUsers)
        }}
      />,
      {
        autoClose: 5000,
        hideProgressBar: true,
        onClose: async () => {
          if (!isRevertingRef.current) {
            const data = {
              IDsUserUsersContacts: selectedUsers.map((user) => user.id_user),
              IDsContactUsersContacts: [id],
            }
            const response = await ContactService.createUserContact(data)

            if (response.status === 201) {
              toast.success('Usuário atribuído com sucesso', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
              setUpdate(!update)
            } else {
              toast.error('Erro ao atribuir usuário', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            }
          }
          isRevertingRef.current = false
          setSelectedUsers([])
        },
      },
    )
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Adicionar Usuários</Title>
          <Subtitle>Selecione os usuários que deseja adicionar</Subtitle>
        </Header>
        <ListContacts>
          <Input
            placeholder="Pesquisar usuário"
            value={searchContact}
            onChange={(e) => setSearchContact(e.target.value)}
          />
          {filteredContactsByNameOrNumber().map((user) => (
            <Contact
              key={user.id_user}
              onClick={() => handleSelectUser(user)}
              $isSelected={verifySelectedUser(user)}
            >
              <DivRowContact>
                <NameEmailSection>
                  <ContactText>{user.full_name_user}</ContactText>
                  <Email>{user.email_user}</Email>
                </NameEmailSection>
                {verifySelectedUser(user) && <XCircleStyled />}
              </DivRowContact>
            </Contact>
          ))}
          {filteredContactsByNameOrNumber().length === 0 && (
            <NoContacts>Nenhum usuário encontrado</NoContacts>
          )}
        </ListContacts>
        <DivButton>
          <ButtonCancel onClick={handleCloseDialog}>Cancelar</ButtonCancel>
          <ButtonCadastro onClick={handleSave}>Adicionar</ButtonCadastro>
        </DivButton>
      </Container>
    </Dialog>
  )
}

export default DialogContacts
