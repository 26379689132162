import React, { useEffect } from 'react'
import Dialog from '../../../../../../../../../../shared/components/Dialog'
import Input from '../../../../../../../../../../shared/components/Input'
import { Button, ButtonContainer, SpanError, Subtitle, Title } from './styles'
import { useTheme } from 'styled-components'
import ContactService from '../../../../../../../../../../shared/services/Contact/ContactService'
import { Bounce, toast } from 'react-toastify'

const EditMessage = ({
  open,
  onChangeOpen,
  user,
  setUpdate,
  update,
  toastDisabled,
}) => {
  const theme = useTheme()
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const [dialogWidth, setDialogWidth] = React.useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  // React.useEffect(() => {
  //   console.log('Mensagem recebida no modal:', message)
  // }, [message])

  useEffect(() => {
    setPassword('')
  }, [open])

  const handleDelete = async () => {
    const res = await ContactService.deleteUserContact(
      user.id_users_contacts,
      password,
    )
    if (res.status === 204) {
      toast.success('Atribuição deletada com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      onChangeOpen(false)
      setUpdate(!update)
    } else {
      toast.error('Ocorreu um erro ao deletar a atribuição', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })

      if (res.request.status === 401) {
        setError('Senha incorreta')
      } else {
        setError('Ocorreu um erro ao deletar o contato')
      }
    }
  }

  return (
    <Dialog open={open} onChangeOpen={onChangeOpen} width={dialogWidth}>
      <Title>Apagar Usuário:</Title>
      <Subtitle>
        Para deletar o usuário, por favor, confirme sua senha:
      </Subtitle>
      <Input
        type="password"
        placeholder="Digite sua senha"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        style={{
          backgroundColor: theme.colors.guto.FundoAzul,
        }}
      />
      <SpanError>{error}</SpanError>
      <ButtonContainer>
        <Button onClick={() => onChangeOpen(false)}>Cancelar</Button>
        <Button
          onClick={() => {
            handleDelete()
          }}
        >
          Confirmar
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default EditMessage
