import { useState, useEffect, useRef, useCallback } from 'react'
import FileService from '../../../../../../../../shared/services/File/FileService'
import Dialog from '../../../../../../../../shared/components/Dialog'
import {
  MidiaListContainer,
  MidiaItem,
  MidiaImage,
  MidiaVideo,
  MidiaAudio,
  MidiaDocument,
  LoadMoreButton,
} from './styles' // Importe os styled components
import LoadingSpinner from '../../../../../../../../shared/components/LoadingSpinner'
import { Plus } from 'phosphor-react'
import { useTheme } from 'styled-components'
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFile,
} from 'react-icons/fa'

const LIMIT_MIDIAS = 5 // Defina um limite para as mídias carregadas por vez

const DialogMidia = ({ open, onChangeOpen, socket, dataContact }) => {
  const [midias, setMidias] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const midiaListRef = useRef(null)
  const [offset, setOffset] = useState(0)
  const [selectedMedia, setSelectedMedia] = useState(null) // Estado para mídia expandida
  const theme = useTheme()
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const loadMidias = useCallback(
    async (newOffset) => {
      if (!dataContact?.id) return

      setLoading(true)

      socket.emit(
        'getMessages',
        { idContact: dataContact.id },
        {
          limit: LIMIT_MIDIAS,
          offset: newOffset,
          only_media: true,
        },
        async (err, resp) => {
          setLoading(false)
          if (err) {
            console.log(err)
            return
          }

          if (resp.length === 0) {
            setHasMore(false)
            return
          }

          try {
            const midiasComBase64 = await Promise.all(
              resp.map(async (element) => {
                console.log(element) // apagar isso
                const file = await FileService.getSrc(
                  element.file_name_message,
                  element.file_type_message,
                )

                const data = {
                  content: element.content_message,
                  base64: file.data.base64,
                  type: element.file_type_message.split(';')[0].split(':')[1],
                }
                return data
              }),
            )

            setMidias((prevMidias) => [...prevMidias, ...midiasComBase64])
          } catch (error) {
            console.error('Erro ao carregar mídias:', error)
          }
        },
      )
    },
    [socket, dataContact?.id],
  )

  useEffect(() => {
    if (open) {
      setMidias([])
      setHasMore(true)
      setOffset(0)
      loadMidias(0)
    }
  }, [open, loadMidias])

  const handleLoadMore = () => {
    const newOffset = offset + LIMIT_MIDIAS
    setOffset(newOffset)
    loadMidias(newOffset)
  }

  const handleMediaClick = (midia) => {
    setSelectedMedia(midia)
  }

  const handleCloseExpandedMedia = () => {
    setSelectedMedia(null)
  }

  // const renderMedia = (midia) => {
  //   if (!midia.base64) {
  //     return <p>Erro ao carregar mídia</p>
  //   }

  //   if (midia.type?.startsWith('image')) {
  //     return (
  //       <MidiaImage
  //         src={midia.base64}
  //         alt="media message"
  //         onClick={() => handleMediaClick(midia)}
  //         style={{ cursor: 'pointer' }}
  //       />
  //     )
  //   }

  //   if (midia.type?.startsWith('video')) {
  //     return (
  //       <MidiaVideo
  //         src={midia.base64}
  //         controls={false}
  //         className="imageOrientation"
  //         onClick={() => handleMediaClick(midia)}
  //         style={{ cursor: 'pointer' }}
  //       />
  //     )
  //   }

  //   if (midia.type?.startsWith('document')) {
  //     return (
  //       <MidiaDocument>
  //         <span>{'Documento'}</span>
  //         <a href={midia.base64} download={midia.content || 'download'}>
  //           Baixar
  //         </a>
  //       </MidiaDocument>
  //     )
  //   }

  //   if (midia.type?.startsWith('audio')) {
  //     return (
  //       <MidiaAudio
  //         src={midia.base64}
  //         controls
  //         style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
  //       />
  //     )
  //   }

  //   return (
  //     <div className="media-message">
  //       <a href={midia.base64} download={midia.content}>
  //         Fazer Download de {midia.content}
  //       </a>
  //     </div>
  //   )
  // }

  const renderMedia = (midia) => {
    if (!midia.base64) {
      return <p>Erro ao carregar mídia</p>
    }

    if (midia.type?.startsWith('image')) {
      return (
        <MidiaImage
          src={midia.base64}
          alt="media message"
          onClick={() => handleMediaClick(midia)}
          style={{ cursor: 'pointer' }}
        />
      )
    }

    if (midia.type?.startsWith('video')) {
      return (
        <MidiaVideo
          src={midia.base64}
          controls={false}
          className="imageOrientation"
          onClick={() => handleMediaClick(midia)}
          style={{ cursor: 'pointer' }}
        />
      )
    }

    if (
      midia.type?.startsWith('document') ||
      midia.type?.startsWith('application')
    ) {
      let icon
      if (midia.type?.endsWith('pdf')) {
        icon = <FaFilePdf size={40} />
      } else if (midia.type?.endsWith('doc') || midia.type?.endsWith('docx')) {
        icon = <FaFileWord size={40} />
      } else if (midia.type?.endsWith('xls') || midia.type?.endsWith('xlsx')) {
        icon = <FaFileExcel size={40} />
      } else if (midia.type?.endsWith('ppt') || midia.type?.endsWith('pptx')) {
        icon = <FaFilePowerpoint size={40} />
      } else {
        icon = <FaFile size={40} />
      }

      return (
        <MidiaDocument>
          {icon}
          <a href={midia.base64} download={midia.content || 'download'}>
            Baixar
          </a>
        </MidiaDocument>
      )
    }

    if (midia.type?.startsWith('audio')) {
      return (
        <MidiaAudio
          src={midia.base64}
          controls
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }

    return (
      <div className="media-message">
        <a href={midia.base64} download={midia.content}>
          Fazer Download de {midia.content}
        </a>
      </div>
    )
  }

  return (
    <>
      <Dialog
        open={open}
        onChangeOpen={onChangeOpen}
        midiaGallery={true}
        width={dialogWidth}
      >
        <MidiaListContainer ref={midiaListRef}>
          {midias.length > 0 ? (
            midias.map((midia, index) => (
              <MidiaItem key={index}>{renderMedia(midia)}</MidiaItem>
            ))
          ) : (
            <h1>Nenhuma mídia encontrada</h1>
          )}
          {loading && <LoadingSpinner strokeColor={theme.colors.Text} />}
          {hasMore && !loading && (
            <LoadMoreButton onClick={handleLoadMore}>
              <Plus size={20} />
            </LoadMoreButton>
          )}
        </MidiaListContainer>
      </Dialog>

      {/* Modal para mídia expandida */}
      {selectedMedia && (
        <Dialog open={!!selectedMedia} onChangeOpen={handleCloseExpandedMedia}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {selectedMedia.type?.startsWith('image') && (
              <img
                src={selectedMedia.base64}
                alt="Expanded Media"
                style={{ maxWidth: '100%' }}
              />
            )}

            {selectedMedia.type?.startsWith('video') && (
              <video
                src={selectedMedia.base64}
                controls
                style={{ maxWidth: '100%' }}
              />
            )}

            <a
              href={selectedMedia.base64}
              download={selectedMedia.content || 'download'}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                backgroundColor: '#007BFF',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '5px',
              }}
            >
              Baixar Mídia
            </a>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default DialogMidia
