import styled from 'styled-components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import {
  CaretLeft,
  Tag,
  UserList,
  ArrowsClockwise,
  Aperture,
  Robot,
} from 'phosphor-react'

export const ApertureStyled = styled(Aperture)`
  font-size: large;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 10vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
`

export const ContactImage = styled.img`
  width: auto;
  height: 80%;
  border-radius: 50%;
  background-color: white;
  margin: 0.5rem;
  z-index: 3;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const SyncButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const ArchiveButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
`

export const NameContact = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 0.5rem;
`

export const ModalImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`

export const EditButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const ModalImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalNameSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ModalDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.Text};

  p {
    font-size: 1rem;
    text-align: center;
    word-break: break-word;
  }
`

export const NameContactEnter = styled.div`
  font-size: 1.5rem;
  font-weight: 800;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.Text};
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const ModalNumberSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.Text};
`

export const InputField = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  width: 200px;
  text-align: center;
`

export const InputLabel = styled.label`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
  margin-bottom: 5px;
  display: block;
`

export const InputBox = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  color: ${({ theme }) => theme.colors.Text};
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  transition: all 0.3s;
`

export const MoreVertIconStyled = styled(MoreVertIcon)`
  font-size: large;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  margin-left: -0.5rem;
`

export const SearchIconStyled = styled(SearchIcon)`
  font-size: large;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CaretLeftStyled = styled(CaretLeft)`
  display: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  font-size: large;

  @media (max-width: 800px) {
    display: flex;
  }
`

export const UserListStyled = styled(UserList)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const RobotStyled = styled(Robot)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const TagStyled = styled(Tag)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ArrowsClockwiseStyled = styled(ArrowsClockwise)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 0.8rem;
  font-weight: 500;
`
export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  margin-top: 0.25rem;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none; /* Remover a borda de foco padrão para melhor estilo */
  }
`
