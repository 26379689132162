import { useEffect, useRef, useState } from 'react'
import {
  Main,
  DateBox,
  Container,
  ImageMine,
  ImageNotMine,
  IconNotMine,
  IconMine,
  ContainerMessage,
  DownloadMessage,
  TimeDifference,
  TooltipContent,
  TooltipArrow,
  Sticker,
  MessageImg,
  MessageVideo,
  VCard,
  VCardName,
  VCardPhone,
  VCardHeader,
  VCardImage,
  VCardButton,
  // ContextMenuContent,
  // ContextMenuItem,
  // ContextMenuItemContainer,
  TrashStyled,
  PencilStyled,
  EditedMessage,
  HeartStyled,
  DivSticker,
  ReplyContainer,
  ReplyMessage,
  ReplyAuthor,
  ArrowBendUpLeftStyled,
  StyledMenu,
  StyledMenuItem,
} from './styles'
import {
  formatTimeSent,
  formatDateChat,
  extractImage,
  processMessage,
} from '../../../../../../utils/format'
import DialogSeeMedia from '../../../../../../shared/components/DialogSeeMedia'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import * as Tooltip from '@radix-ui/react-tooltip'
import imageContato from '../../../../../../assets/images/imageContato'
import UserService from '../../../../../../shared/services/User/UserService'
import FileService from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import MessageService from '../../../../../../shared/services/Message/MessageService'
import EditMessage from './components/EditMessage'
import ReplyMessageModal from './components/ReplyMessage'
import StickerService from '../../../../../../shared/services/Sticker/StickerService'
import { Bounce, toast } from 'react-toastify'

const Message = ({
  message,
  dataContact,
  setMessages,
  currentMessages,
  setLateralContacts,
  setNewMessage,
  handleSendMessage,
  setTitle,
  setFileChat,
}) => {
  const theme = useTheme()
  const [openEditMessage, setOpenEditMessage] = useState(false)
  const [openReplyMessage, setOpenReplyMessage] = useState(false)
  const [imageOrientation, setImageOrientation] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mediaSrc, setMediaSrc] = useState(null)
  const [mediaClicked, setMediaClicked] = useState(false)
  const { contactImage, setUserImage, userImage } = useImage()
  const getUserImage = async (id) => {
    return await UserService.getUserById(id)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const openContext = Boolean(anchorEl)

  const handleContextMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [file, setFile] = useState(null)
  const [loadingReplay, setLoadingReplay] = useState(true)

  useEffect(() => {
    //
    if (message.fileNameReply && message.fileTypeReply) {
      setLoadingReplay(true)
      FileService.getSrc(message.fileNameReply, message.fileTypeReply)
        .then((resp) => {
          setFile(resp.data.base64)
          setLoadingReplay(false)
        })
        .catch((error) => {
          console.log(error)
          setLoadingReplay(false)
        })
    } else {
      setLoadingReplay(false)
    }
  }, [message.fileNameReply, message.fileTypeReply])

  useEffect(() => {
    const loadImage = async () => {
      if (!userImage[message.senderUserId]) {
        try {
          const res = await getUserImage(message.senderUserId)
          if (
            res.data.picture_filename_user &&
            res.data.picture_filetype_user
          ) {
            const base64 = await getBase64Media(
              res.data.picture_filename_user,
              res.data.picture_filetype_user,
            )
            if (base64) {
              setUserImage(message.senderUserId, base64)
            } else {
              setUserImage(message.senderUserId, imageContato)
            }
          }
        } catch (error) {
          console.log('Error loading image', error)
          setUserImage(message.senderUserId, imageContato)
        }
      }
    }
    if (message.senderUserId) {
      loadImage()
    }
    // eslint-disable-next-line
  }, [message.senderUserId]);

  useEffect(() => {
    if (mediaSrc) {
      if (message.fileType?.startsWith('data:image')) {
        const img = new Image()
        img.src = mediaSrc

        img.onload = () => {
          if (img.naturalWidth > img.naturalHeight) {
            setImageOrientation('landscape')
          } else {
            setImageOrientation('portrait')
          }
        }
      } else if (message.fileType?.startsWith('data:video')) {
        const video = document.createElement('video')
        video.src = mediaSrc

        video.onloadedmetadata = () => {
          if (video.videoWidth > video.videoHeight) {
            setImageOrientation('landscape')
            console.log('landscape')
          } else {
            setImageOrientation('portrait')
            console.log('portrait')
          }
        }
      }
    }
  }, [mediaSrc, message.fileType])

  useEffect(() => {
    if (message.typeMessage === 'sticker') {
      setLoading(true)
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error fetching media:', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.typeMessage])

  useEffect(() => {
    if (mediaClicked && message.fileName && message.fileType) {
      getBase64Media(message.fileName, message.fileType)
        .then((res) => {
          setMediaSrc(res)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error fetching media:', err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaClicked, message.fileName, message.fileType])

  useEffect(() => {
    if (videoRef.current) {
      if (open) {
        videoRef.current.pause()
      }
    }
  }, [open])

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }
  const handleMediaClick = () => {
    setLoading(true)
    setMediaClicked(true)
  }

  const videoRef = useRef(null)

  const renderMedia = () => {
    if (loading) {
      return <LoadingSpinner strokeColor={theme.colors.Text} />
    }
    if (!mediaSrc && message.fileType === 'data:image/jpeg;base64') {
      return <p>Clique para carregar imagem</p>
    } else if (!mediaSrc && message.fileType === 'data:video/mp4;base64') {
      return <p>Clique para carregar vídeo</p>
    } else if (!mediaSrc && message.fileType.startsWith('data:audio')) {
      return <p>Clique para carregar áudio</p>
    } else if (!mediaSrc) {
      return <p>Clique para carregar arquivo</p>
    }
    if (message.fileType.startsWith('data:image')) {
      return (
        <MessageImg
          src={mediaSrc}
          onClick={() => setOpen(true)}
          alt="media message"
          className={imageOrientation}
        />
      )
    }

    if (message.fileType.startsWith('data:video')) {
      return (
        <MessageVideo
          ref={videoRef}
          src={mediaSrc}
          onClick={() => setOpen(true)}
          controls
          className={imageOrientation}
        />
      )
    }
    if (message.fileType.startsWith('data:audio')) {
      return (
        <audio
          src={mediaSrc}
          controls
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )
    }
    return (
      <div className="media-message">
        <a href={mediaSrc} download={message.fileName}>
          Fazer Download do arquivo
        </a>
      </div>
    )
  }

  const handleSaveContact = async () => {
    const fullNameContact = message.message.split(';')[0]
    const idContact = message.message.split(';')[1]
    const data = {
      fullNameContact,
      idContact: idContact.concat('@c.us'),
    }

    const res = await ContactService.storeContact(data)
    if (res.status === 201) {
      toast.success('Contato salvo com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    } else {
      toast.error('Erro ao salvar contato', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  const handleDeleteMessage = (messageId, contactId) => {
    const now = new Date()
    const sendDate = new Date(message.sendDateMessage)
    if (now - sendDate > 172800000) {
      toast.error('Erro ao excluir mensagem', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    } else {
      MessageService.delete(messageId)
        .then(() => {
          setMessages((prev) => {
            const newMessages = { ...prev }
            if (Array.isArray(newMessages[contactId])) {
              newMessages[contactId] = newMessages[contactId].map((msg) => {
                if (msg.idMessage === message.idMessage) {
                  return {
                    ...msg,
                    isDeletedMessage: true,
                  }
                }
                return msg
              })
            }
            return newMessages
          })
          setLateralContacts((prev) => {
            const newContacts = [...prev]
            newContacts.map((contact) => {
              if (contact.id === contactId) {
                if (contact.lastMessageId === messageId) {
                  contact.lastMessage = message[message.length - 1]?.message
                }
              }
              return contact
            })
            return newContacts
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleReplyModal = () => {
    if (message.isNoteMessage) {
      toast.error('Não é possível responder mensagens de nota', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      return
    }
    setOpenReplyMessage(true)
  }

  const handleEditModal = () => {
    const now = new Date()
    const sendDate = new Date(message.sendDateMessage)

    if (now - sendDate > 840000) {
      toast.error('Tempo de edição da mensagem expirado!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      return
    }

    if (message.isNoteMessage) {
      toast.error('Não é possível editar mensagens de nota', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      return
    }

    if (
      message.typeMessage !== 'chat' &&
      message.typeMessage !== 'image' &&
      message.typeMessage !== 'video' &&
      message.typeMessage !== 'document'
    ) {
      toast.error('Não é possível editar mensagens de mídia', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      return
    }
    setOpenEditMessage(true)
  }

  const handleFavoriteSticker = async () => {
    try {
      const base64Sticker = await getBase64Media(
        message.fileName,
        message.fileType,
      )
      const data = {
        base64_sticker: base64Sticker,
        name_sticker: '',
      }
      await StickerService.FavoriteSticker(data)
      toast.success('Sticker favoritado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    } catch (error) {
      console.error('Error favoriting sticker:', error)
      toast.error('Erro ao favoritar sticker', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  const renderMessage = () => {
    if (message.typeMessage === 'vcard') {
      const name = message.message.split(';')[0]
      const number = message.message.split(';')[1]
      return (
        <VCard>
          <VCardHeader>
            <VCardImage src={imageContato} alt="Imagem do contato" />
            <VCardName>{name}</VCardName>
          </VCardHeader>
          <VCardPhone>{number}</VCardPhone>
          <VCardButton onClick={() => handleSaveContact()}>
            Salvar contato
          </VCardButton>
        </VCard>
      )
    }
    if (message.isDeletedMessage) {
      return (
        <>
          <p>{processMessage(message.message)}</p>
          <EditedMessage>apagada </EditedMessage>
        </>
      )
    } else if (message.isEditedMessage) {
      return (
        <>
          <p>{processMessage(message.message)}</p>
          <EditedMessage>editada </EditedMessage>
        </>
      )
    } else {
      return <p>{processMessage(message.message)}</p>
    }
  }

  const renderSticker = () => {
    return loading ? (
      <LoadingSpinner strokeColor={theme.colors.Text} />
    ) : (
      <Sticker
        src={mediaSrc}
        alt="Sticker"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    )
  }

  const renderReplyMessage = () => {
    if (message.idReplyMessage) {
      let replyContent

      if (loadingReplay) {
        replyContent = <p>Carregando...</p>
      } else {
        switch (message.typeReplyMessage) {
          case 'video':
            replyContent = (
              <video
                src={file}
                alt="Mensagem de mídia respondida"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            )
            break
          case 'audio':
            replyContent = (
              <audio
                src={file}
                alt="Mensagem de mídia respondida"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            )
            break
          case 'image':
            replyContent = (
              <img
                src={file}
                alt="Mensagem de mídia respondida"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            )
            break
          case 'sticker':
            replyContent = (
              <img
                src={file}
                alt="Sticker respondido"
                style={{ maxWidth: '50px', maxHeight: '50px' }}
              />
            )
            break
          case 'document':
            replyContent = <p>Arquivo: {message.fileNameReply}</p>
            break
          default:
            replyContent = <ReplyMessage>{message.replyMessage}</ReplyMessage>
            break
        }
      }

      return (
        <ReplyContainer>
          <ReplyAuthor>
            {message.fromContactReplyMessage ? dataContact.name : 'Você'}
          </ReplyAuthor>
          {replyContent}
        </ReplyContainer>
      )
    }
    return null
  }

  // const renderNotMineMessageContent = () => {
  //   {
  //     /* <ContextMenuItemContainer onClick={() => handleReplyModal()}>
  //     <ContextMenuItem>Responder</ContextMenuItem>
  //     <ArrowBendUpLeftStyled />
  //   </ContextMenuItemContainer> */
  //   }
  //   if (message.typeMessage === 'sticker') {
  //     return (
  //       <ContextMenu.Root>
  //         <ContextMenu.Trigger>
  //           <DivSticker>
  //             {renderSticker()}
  //             <TimeDifference>
  //               {formatTimeSent(message.sendDateMessage)}
  //             </TimeDifference>
  //           </DivSticker>
  //         </ContextMenu.Trigger>
  //         <ContextMenuContent>
  //           <ContextMenuItemContainer
  //             onClick={async () => {
  //               try {
  //                 await handleFavoriteSticker()
  //               } catch (error) {
  //                 console.error('Error favoriting sticker:', error)
  //               }
  //             }}
  //           >
  //             <ContextMenuItem>Favoritar</ContextMenuItem>
  //             <HeartStyled />
  //           </ContextMenuItemContainer>
  //         </ContextMenuContent>
  //       </ContextMenu.Root>
  //     )
  //   }
  //   return (
  //     <>
  //       {renderReplyMessage()}
  //       {message.fileType &&
  //         (message.fileType.startsWith('image/') ||
  //           message.fileType.startsWith('video/') ||
  //           message.fileType.startsWith('audio/') ||
  //           message.fileType) && (
  //           <DownloadMessage
  //             className="media-message"
  //             onClick={mediaClicked ? null : handleMediaClick}
  //           >
  //             {renderMedia()}
  //           </DownloadMessage>
  //         )}
  //       {renderMessage()}
  //       <TimeDifference>
  //         {formatTimeSent(message.sendDateMessage)}
  //       </TimeDifference>
  //     </>
  //   )
  // }

  const renderNotMineMessageContent = () => {
    return (
      <>
        <div
          onContextMenu={handleContextMenu}
          style={{ cursor: 'context-menu' }}
        >
          {message.typeMessage === 'sticker' ? (
            <DivSticker>
              {renderSticker()}
              <TimeDifference>
                {formatTimeSent(message.sendDateMessage)}
              </TimeDifference>
            </DivSticker>
          ) : (
            <>
              {renderReplyMessage()}
              {message.fileType &&
                (message.fileType.startsWith('image/') ||
                  message.fileType.startsWith('video/') ||
                  message.fileType.startsWith('audio/') ||
                  message.fileType) && (
                  <DownloadMessage
                    className="media-message"
                    onClick={mediaClicked ? null : handleMediaClick}
                  >
                    {renderMedia()}
                  </DownloadMessage>
                )}
              {renderMessage()}
              <TimeDifference>
                {formatTimeSent(message.sendDateMessage)}
              </TimeDifference>
            </>
          )}
        </div>

        <StyledMenu
          anchorEl={anchorEl}
          open={openContext}
          onClose={handleClose}
          onClick={handleClose}
        >
          {message.typeMessage === 'sticker' && (
            <StyledMenuItem
              onClick={async () => {
                try {
                  await handleFavoriteSticker()
                } catch (error) {
                  console.error('Error favoriting sticker:', error)
                }
              }}
            >
              Favoritar
              <HeartStyled />
            </StyledMenuItem>
          )}
          <StyledMenuItem onClick={handleReplyModal}>
            Responder
            <ArrowBendUpLeftStyled />
          </StyledMenuItem>
        </StyledMenu>
      </>
    )
  }

  const renderMineMessageContent = () => {
    return (
      <>
        <div
          onContextMenu={handleContextMenu}
          style={{ cursor: 'context-menu' }}
        >
          {renderReplyMessage()}
          {message.typeMessage === 'sticker'
            ? renderSticker()
            : message.fileType &&
              (message.fileType.startsWith('image/') ||
                message.fileType.startsWith('video/') ||
                message.fileType.startsWith('audio/') ||
                message.fileType) && (
                <DownloadMessage
                  className="media-message"
                  onClick={mediaClicked ? null : handleMediaClick}
                >
                  {renderMedia()}
                </DownloadMessage>
              )}
          {renderMessage()}
          <TimeDifference onClick={() => handleEditModal()}>
            {formatTimeSent(message.sendDateMessage)}
          </TimeDifference>
        </div>
        <StyledMenu
          anchorEl={anchorEl}
          open={openContext}
          onClose={handleClose}
          onClick={handleClose}
        >
          <StyledMenuItem onClick={handleEditModal}>
            Editar <PencilStyled />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() =>
              handleDeleteMessage(message.idMessage, dataContact.id)
            }
          >
            Apagar
            <TrashStyled />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleReplyModal}>
            Responder
            <ArrowBendUpLeftStyled />
          </StyledMenuItem>
        </StyledMenu>
      </>
    )
  }

  return (
    <Main id={message.idMessage}>
      <DateBox $isFlag={message.isFlag}>
        <p>{formatDateChat(message.date)}</p>
      </DateBox>
      <Container $isFlag={message.isFlag} $isMine={message.isMine}>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ImageNotMine
            $isMine={message.isMine}
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ImageNotMine
            $isMine={message.isMine}
            src={imageContato}
            alt="Imagem do contato"
          />
        )}
        <IconNotMine $isMine={message.isMine} />
        <ContainerMessage
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        >
          {message.isMine
            ? renderMineMessageContent()
            : renderNotMineMessageContent()}
        </ContainerMessage>
        <IconMine
          $isMine={message.isMine}
          $isNoteMessage={message.isNoteMessage}
        />
        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <ImageMine
                $isMine={message.isMine}
                src={userImage[message.senderUserId] || imageContato}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <TooltipContent className="TooltipContent" sideOffset={5}>
                {message.userName ? (
                  <p>Enviado por {message.userName}</p>
                ) : (
                  <p>Impossível determinar autor</p>
                )}
                <TooltipArrow />
              </TooltipContent>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
        <DialogSeeMedia
          open={open}
          onOpenChange={setOpen}
          mediaSrc={mediaSrc}
          type={message.typeMessage}
        />
        <EditMessage
          message={message}
          open={openEditMessage}
          onChangeOpen={setOpenEditMessage}
          setMessages={setMessages}
          currentMessages={currentMessages}
          contactId={dataContact.id}
          setLateralContacts={setLateralContacts}
        />
        <ReplyMessageModal
          open={openReplyMessage}
          onChangeOpen={setOpenReplyMessage}
          message={message}
          sendMessage={handleSendMessage}
          setNewMessage={setNewMessage}
          setTitle={setTitle}
          setFile={setFileChat}
        />
      </Container>
    </Main>
  )
}

export default Message
