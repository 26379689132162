import styled from 'styled-components'

// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 3rem;
//   background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
//   min-height: 100vh;
//   width: 100%;
// `

// export const ProfileContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background: ${({ theme }) => theme.colors.guto.Azul3};
//   padding: 3rem;
//   border-radius: 20px;
//   box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
//   width: 100%;
//   max-width: 600px; /* Aumentado de 400px para 600px */
//   min-height: 500px; /* Adicionado para deixar maior */
// `

// export const Avatar = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   margin-bottom: 1rem;
// `

// export const Info = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-bottom: 1rem;
// `

// export const Label = styled.span`
//   font-weight: bold;
//   color: ${({ theme }) => theme.colors.Text};
// `

// export const Value = styled.span`
//   color: ${({ theme }) => theme.colors.Text};
// `

// export const EditButton = styled.button`
//   padding: 0.5rem 1rem;
//   background-color: ${({ theme }) => theme.colors.guto.Azul1};
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: 0.3s;

//   &:hover {
//     background-color: ${({ theme }) => theme.colors.guto.Azul2};
//   }
// `

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  padding: 1.8rem 1rem;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  height: 10vh;
`

export const BackButton = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 1%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  font-size: 1.5rem;
  font-weight: 500;
  @media (max-width: 800px) {
    display: flex;
  }
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.guto.Azul3};
  padding: 4rem; /* Aumentei o padding */
  border-radius: 20px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px; /* Expandido para 800px */
  min-height: 600px; /* Expandido para 600px */
`

export const Avatar = styled.img`
  width: 150px; /* Aumentado */
  height: 150px;
  border-radius: 50%;
  margin-bottom: 2rem;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

export const Label = styled.span`
  font-weight: bold;
  font-size: 1.5rem; /* Texto maior */
  color: ${({ theme }) => theme.colors.Text};
`

export const Value = styled.span`
  font-size: 1.4rem; /* Texto maior */
  color: ${({ theme }) => theme.colors.Text};
`

export const EditButton = styled.button`
  padding: 1rem 2rem; /* Botão maior */
  font-size: 1.2rem; /* Texto maior no botão */
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.guto.Azul2};
  }
`
