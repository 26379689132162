import styled from 'styled-components'
import { Sticker, Smiley } from 'phosphor-react'

// export const EmojiComponent = styled.div`
//   position: absolute;
//   z-index: 1000;
//   bottom: 2.5rem;
//   max-width: 95vw;
// `

export const EmojiComponent = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 2.5rem;
  max-width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 5px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const StickerIcon = styled(Sticker)`
  size: 24;
  color: ${({ $isChecked, theme }) =>
    $isChecked ? theme.colors.guto.Azul1 : theme.colors.SubText};
  cursor: pointer;
`

export const SmilyIcon = styled(Smiley)`
  size: 24;
  color: ${({ $isChecked, theme }) =>
    $isChecked ? theme.colors.guto.Azul1 : theme.colors.SubText};
`
