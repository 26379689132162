import React, { useEffect } from 'react'
import TagsService from '../../../../../../shared/services/Tags/TagsService'
import DialogTags from './components/DialogTags'
import {
  BoxContainer,
  Tag,
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from './styles'
import { getTextColor } from '../../../../../../utils/format'
import {
  Header,
  StyledPencil,
  StyledPlusCircle,
  StyledTrash,
  Title,
} from '../Gerenciamento/styles'
import { Container } from 'react-bootstrap'
import Listview from '../../../../../../shared/components/Listview'
import { BackButton } from '../../styles'
import { CaretLeft } from 'phosphor-react'
import { useTheme } from 'styled-components'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { Bounce, toast } from 'react-toastify'

const Tags = ({ setNavigation }) => {
  const [tagsIsLoading, setTagsIsLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [openDialog, setOpenDialog] = React.useState(false)
  const [selectedTag, setSelectedTag] = React.useState(null)
  const [update, setUpdate] = React.useState(false)
  const theme = useTheme()

  const handleCreateTag = () => {
    setSelectedTag(null)
    setOpenDialog(true)
  }

  const handleEditTag = async (tag) => {
    setSelectedTag(tag)
    setOpenDialog(true)
  }

  const handleDeleteTag = async (tag) => {
    const response = await TagsService.deleteTag(tag.id_tag)
    if (response.status === 204) {
      toast.success('Tag deletada com sucesso', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setUpdate(!update)
    } else {
      toast.error('Erro ao deletar tag', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const renderHeader = (tag) => {
    return (
      <Tag>
        <TagActions>
          <TagText>{tag.name_tag}</TagText>
          <TagText>-</TagText>
          <TagAbbreviation $color={tag.color_hex_tag}>
            <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
              {tag.abbreviation_tag}
            </TextAbbreviation>
          </TagAbbreviation>
        </TagActions>
        <TagActions>
          <StyledPencil size={24} onClick={() => handleEditTag(tag)} />
          <StyledTrash size={24} onClick={() => handleDeleteTag(tag)} />
        </TagActions>
      </Tag>
    )
  }

  useEffect(() => {
    const inicializeData = async (responseData) => {
      setData({
        title: 'Gerenciamento',
        accordion: true,
        create: (
          <StyledPlusCircle size={24} onClick={() => handleCreateTag()} />
        ),
        filter: true,
        itens: responseData.map((tag) => ({
          id: tag.id_tag,
          object: tag,
          objectFilter: tag.name_tag,
          header: renderHeader,
        })),
      })
    }

    const getTags = async () => {
      const response = await TagsService.getTags()
      await inicializeData(response.data)
      setTagsIsLoading(false)
    }
    getTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  if (tagsIsLoading) {
    return (
      <Container>
        <Header>
          <BackButton onClick={() => setNavigation('configList')}>
            <CaretLeft
              size={32}
              weight="bold"
              style={{
                marginTop: '0.5rem',
              }}
            />
          </BackButton>
          <Title>Tags</Title>
        </Header>
        <BoxContainer className="loading">
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </BoxContainer>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.5rem',
            }}
          />
        </BackButton>
        <Title>Tags</Title>
      </Header>
      <BoxContainer>
        <Listview data={data} />
      </BoxContainer>
      <DialogTags
        open={openDialog}
        onChangeOpen={setOpenDialog}
        setUpdate={setUpdate}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    </Container>
  )
}

export default Tags
