import { BrowserRouter } from 'react-router-dom'
import Rotas from './routes/index'
import GlobalStyles from './styles/global'
import { ThemeProvider } from './hook/theme'
import { SessionProvider } from './hook/session'
import { TriggersProvider } from './hook/triggers'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <TriggersProvider>
          <SessionProvider>
            <ThemeProvider>
              <ToastContainer
                style={{ pointerEvents: 'auto' }}
                onClick={(e) => e.stopPropagation()}
              />
              <Rotas />
            </ThemeProvider>
          </SessionProvider>
        </TriggersProvider>
      </BrowserRouter>
    </>
  )
}

export default App
