import React, { useState, useEffect, useRef } from 'react'
import { Bounce, toast } from 'react-toastify'
import {
  Container,
  Button,
  AudioPlayer,
  AudioPreview,
  SaveButton,
  MicIcon,
  RecContainer,
  Line,
  SelectContainer,
  AudioContainerRow,
  ErrorsContainer,
  ErrorSpan,
  Div,
  LoadingContainer,
  BoxContainer,
} from './style'

import {
  AudioItem,
  AudioLabel,
  AudioLabelToolTip,
  AudioPlayer as SharedAudioPlayer,
  Actions,
  DeleteButton,
} from '../../../../../../shared/components/AudioLibrary/styles.js'
import {
  TooltipArrow,
  TooltipContent,
} from '../../../../../Home/components/Chat/components/Message/styles.js'
import * as Tooltip from '@radix-ui/react-tooltip'
import { StyledTrash } from '../Gerenciamento/styles.js'
import { Header, Title, Subtitle } from '../Geral/styles'
import { CaretLeft, Microphone } from 'phosphor-react'
import UserService from '../../../../../../shared/services/User/UserService'
import Input from '../../../../../../shared/components/InputForm/index.jsx'
import { formatTime } from '../../../../../../utils/format.js'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner/index.jsx'
import { useTheme } from 'styled-components'
import FileService from '../../../../../../shared/services/File/FileService.js'
import { BackButton } from '../../styles.js'

const AudioLibraryStore = ({ setNavigation }) => {
  const [audioName, setAudioName] = useState('')
  const [audioBase64, setAudioBase64] = useState(null)
  const [mediaRecorder, setMediaRecorder] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState(null)
  const [recordingTime, setRecordingTime] = useState(0)
  const [errors, setErrors] = useState([])
  const [audios, setAudios] = useState([])
  const [audioSources, setAudioSources] = useState({})
  const [loading, setLoading] = useState(true)
  const recordingInterval = useRef(null)
  const theme = useTheme()
  const [audioState, setAudioState] = useState(false)

  const fileInputRef = useRef(null)

  useEffect(() => {
    if (audioBlob) {
      const audio = new File([audioBlob], 'audioMessage.webm', {
        type: 'audio/webm',
      })
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64Audio = reader.result
        setAudioBase64(base64Audio)
      }

      reader.readAsDataURL(audio)
    }
  }, [audioBlob])

  useEffect(() => {
    fetchAudio()
    // eslint-disable-next-line
  }, [audioState])

  const getSrc = async (filename, filetype) => {
    try {
      const response = await FileService.getSrc(filename, filetype)
      return response.data.base64
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const fetchAudio = async () => {
    try {
      const response = await UserService.getAudios()
      setAudios(Array.isArray(response.data) ? response.data : [])

      const sources = await Promise.all(
        response.data.map(async (audio) => {
          const base64 = await getSrc(
            audio.filename_audio,
            audio.filetype_audio,
          )
          return { id: audio.id_users_audios, src: base64 }
        }),
      )

      const newAudioSources = sources.reduce((acc, { id, src }) => {
        acc[id] = src
        return acc
      }, {})

      setAudioSources(newAudioSources)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteAudio = async (id) => {
    try {
      UserService.deleteAudio(id)
      setAudios((prev) => prev.filter((audio) => audio.id_users_audios !== id))
    } catch (error) {
      console.log(error)
    }
  }

  const startRecording = async () => {
    try {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
        mediaRecorder.start()
        setIsRecording(true)
        recordingInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1)
        }, 1000)

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data)
        }
      })
    } catch (err) {
      console.log('Error recording audio', err)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop()
      setIsRecording(false)
      clearInterval(recordingInterval.current)
      setRecordingTime(0)
    }
  }

  const handleAudioUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setAudioBase64(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      console.error('Nenhum arquivo selecionado.')
    }
  }

  const handleSave = () => {
    setErrors([])
    setErrors((prev) => {
      let updatedErrors = [...prev]

      if (
        !audioBase64 &&
        !updatedErrors.some((error) => error.errorType === 'audio64')
      ) {
        updatedErrors = [
          ...updatedErrors,
          { message: 'Nenhum audio selecionado.', errorType: 'audio64' },
        ]
      }

      if (
        !audioName &&
        !updatedErrors.some((error) => error.errorType === 'audioName')
      ) {
        updatedErrors = [
          ...updatedErrors,
          { message: 'Nome do audio e obrigatorio', errorType: 'audioName' },
        ]
      }

      return updatedErrors
    })

    if (audioBase64 && audioName) {
      UserService.storeAudio(audioBase64, audioName)
        .then(() => {
          toast.success('Audio armazenado com sucesso', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          })
          resetForm()
          setAudioState((prev) => !prev)
        })
        .catch((err) => {
          toast.error('Erro ao armazenar audio', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          })
          console.error('Erro ao salvar áudio:', err)
        })
    }
  }

  const resetForm = () => {
    setAudioName('')
    setAudioBase64(null)
    setAudioBlob(null)
    setIsRecording(false)

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const deletePreview = () => {
    setAudioBase64(null)
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner strokeColor={theme.colors.Text} />
      </LoadingContainer>
    )
  }

  return (
    <Div>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.4rem',
            }}
          />
        </BackButton>
        <Title>Armazenamento de audios</Title>
      </Header>
      <BoxContainer>
        <Container>
          <Input
            type="text"
            placeholder="Nome do áudio"
            value={audioName}
            onChange={(e) => setAudioName(e.target.value)}
            style={{ backgroundColor: theme.colors.guto.Azul2 }}
          />
          <Subtitle>Grave seu audio:</Subtitle>
          <RecContainer>
            {isRecording ? (
              <Button secondary onClick={stopRecording}>
                Parar Gravacao
              </Button>
            ) : (
              <Button onClick={startRecording}>Gravar Áudio</Button>
            )}
            <MicIcon $isRecording={isRecording}>
              <Microphone />
            </MicIcon>
            {isRecording && (
              <Subtitle>Gravando: {formatTime(recordingTime)}</Subtitle>
            )}
          </RecContainer>
          <Line />
          <Subtitle>Selecione seu arquivo de audio:</Subtitle>
          <SelectContainer>
            <Input
              type="file"
              accept="audio/*"
              onChange={handleAudioUpload}
              ref={fileInputRef}
              style={{ backgroundColor: theme.colors.guto.Azul2 }}
            />
          </SelectContainer>
          {audioBase64 && (
            <AudioPreview>
              <Subtitle>Prévia do áudio:</Subtitle>
              <AudioContainerRow>
                <AudioPlayer controls src={audioBase64} />
                <StyledTrash
                  onClick={() => deletePreview()}
                  style={{ marginTop: '8px', cursor: 'pointer' }}
                />
              </AudioContainerRow>
            </AudioPreview>
          )}
          {errors.length > 0 && (
            <ErrorsContainer>
              {errors.map((error, index) => {
                return <ErrorSpan key={index}>{error.message}</ErrorSpan>
              })}
            </ErrorsContainer>
          )}
          <SaveButton onClick={handleSave}>Salvar Áudio</SaveButton>
        </Container>
        <Container>
          <Title
            style={{
              marginBottom: '8px',
            }}
          >
            Lista de audios já gravados:
          </Title>

          {loading ? (
            <LoadingSpinner strokeColor={theme.colors.Text} />
          ) : audios.length === 0 ? (
            <Subtitle>Nenhum audio encontrado</Subtitle>
          ) : (
            audios.map((element) => {
              const src = audioSources[element.id_users_audios]
              return (
                <AudioItem key={element.id_users_audios}>
                  {element.label_audio.length >= 14 ? (
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <AudioLabel
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {element.label_audio.slice(0, 11)}...
                          </AudioLabel>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                          <TooltipContent
                            className="TooltipContent"
                            sideOffset={5}
                          >
                            <AudioLabelToolTip>
                              {element.label_audio}
                            </AudioLabelToolTip>
                            <TooltipArrow />
                          </TooltipContent>
                        </Tooltip.Portal>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  ) : (
                    <AudioLabel>{element.label_audio}</AudioLabel>
                  )}
                  <SharedAudioPlayer
                    controls
                    src={src}
                    onError={(e) =>
                      console.log('Erro ao carregar o áudio:', e.target)
                    }
                    onCanPlay={() => console.log('Áudio pronto para tocar')}
                  />
                  <Actions>
                    <DeleteButton
                      onClick={() => deleteAudio(element.id_users_audios)}
                    />
                  </Actions>
                </AudioItem>
              )
            })
          )}
        </Container>
      </BoxContainer>
    </Div>
  )
}

export default AudioLibraryStore
