import { api } from '../../api/axios'

export default new (class BotfluxApi {
  async getBotfluxs() {
    try {
      const response = await api.get('/botflux')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createBotflux(data) {
    try {
      const response = await api.post('/botflux', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async refreshBotflux() {
    try {
      const response = await api.put('/sync/botflux')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteBotflux(id) {
    try {
      const response = await api.delete(`/botflux/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateBotflux(idFlux, idContact) {
    try {
      const response = await api.put(`/contact/${idContact}`, {
        idBotFluxContact: idFlux,
      })
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async putBotflux(idBotFlux, data) {
    try {
      const response = await api.put(`/botflux/${idBotFlux}`, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async defineInitial(idBotFlux) {
    try {
      const response = await api.put(`/set-first/botflux/${idBotFlux}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async disableBotflux(idContact) {
    try {
      const response = await api.put(`/disable-bot-flux/contact/${idContact}`, {
        idBotFluxContact: null,
      })
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
