import React, { useRef, useState } from 'react'
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  UploadButton,
  ModalImage,
  ImageContainer,
  Div,
} from './styles'
import Input from '../../../../../../shared/components/Input'
import { getFileType } from '../../../../../../utils/format'
import { FaFileAlt } from 'react-icons/fa'

const Modal = ({
  isOpen,
  onClose,
  onDrop,
  onDragOver,
  onDragLeave,
  handleSendMessage,
  title,
  setTitle,
}) => {
  const fileInputRef = useRef(null)
  const [files, setFiles] = useState([])

  const handleClick = () => {
    fileInputRef.current.click()
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const handleMultipleFileSelect = async (event) => {
    const selectedFiles = Array.from(event.target.files)
    if (selectedFiles.length > 0) {
      try {
        const base64Promises = selectedFiles.map((file) =>
          convertToBase64(file).then((base64) => ({
            base64,
            name: file.name,
          })),
        )
        const base64Files = await Promise.all(base64Promises)
        setFiles(base64Files)
      } catch (error) {
        console.error('Erro ao converter arquivos para base64:', error)
      }
    }
  }

  const handleSendMultipleFiles = async () => {
    try {
      files.forEach((file) => {
        const type = getFileType(file.base64)
        handleSendMessage(file.base64, type)
      })
      setFiles([])
      onClose()
    } catch (error) {
      console.error('Erro ao enviar arquivos:', error)
    }
  }

  const truncateFileName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...'
    }
    return name
  }

  if (!isOpen) return null

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Envio de Arquivos</h2>
          <CloseButton
            onClick={() => {
              setFiles([])
              onClose()
            }}
          >
            X
          </CloseButton>
        </ModalHeader>
        <ModalBody
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleMultipleFileSelect}
            multiple
          />
          {files.length > 0 ? (
            <ImageContainer>
              {files.map((file, index) => {
                const type = getFileType(file.base64)
                const truncatedName = truncateFileName(file.name, 20)
                return (
                  <div key={index} style={{ textAlign: 'center' }}>
                    {type === 'image' ? (
                      <ModalImage
                        src={file.base64}
                        alt={`Pré-visualização do arquivo ${index + 1}`}
                      />
                    ) : (
                      <div style={{ padding: '20px' }}>
                        <FaFileAlt size={50} />
                      </div>
                    )}
                    <p
                      title={file.name}
                      style={{
                        marginTop: '5px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {truncatedName}
                    </p>
                  </div>
                )
              })}
            </ImageContainer>
          ) : (
            <p>Clique para selecionar arquivos</p>
          )}
        </ModalBody>
        {files.length > 0 && (
          <Div>
            <Input
              type="text"
              placeholder="Digite legenda para os arquivos"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{ width: '100%', padding: '10px', marginTop: '10px' }}
            />
          </Div>
        )}
        <ModalFooter>
          <UploadButton className="send" onClick={handleSendMultipleFiles}>
            Enviar
          </UploadButton>
          <UploadButton
            onClick={() => {
              setFiles([])
              onClose()
            }}
          >
            Fechar
          </UploadButton>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  )
}

export default Modal
