import React, { useEffect, useState } from 'react'
import UserService from '../../../../../../shared/services/User/UserService.js'
import Listview from '../../../../../../shared/components/Listview/index.jsx'
import {
  BoxContainer,
  Container,
  Header,
  Title,
  StyledPencil,
  StyledPlusCircle,
  StyledAble,
  StyledDisable,
  StyledUp,
  StyledDown,
  UserActions,
} from './styles.js'
import DialogUsers from './components/DialogUsers/index.jsx'
import DialogUsersEdit from './components/DialogUserEdit/index.jsx'
import ListViewUserContacts from './components/ListViewUserContacts/index.jsx'
import { BackButton } from '../../styles.js'
import { AndroidLogo, CaretLeft } from 'phosphor-react'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import { Bounce, toast } from 'react-toastify'
import DialogConfirm from './components/DialogConfirm' // Importe o modal de confirmação

const Gerenciamento = ({ socket, setNavigation }) => {
  const [usersIsLoading, setUsersIsLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState(null)
  const [data, setData] = useState(null)
  const [openDialogUsers, setOpenDialogUsers] = useState(false)
  const [openDialogUsersEdit, setOpenDialogUsersEdit] = useState(false)
  const [updateUsers, setUpdateUsers] = useState(false)
  const [user, setUser] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedUserStatus, setSelectedUserStatus] = useState(null)
  const [search, setSearch] = useState('')
  const theme = useTheme()

  useEffect(() => {
    console.log(selectedUser)
  }, [selectedUser])

  const handleOpenEdit = (user) => {
    setUser(user)
    setOpenDialogUsersEdit(true)
  }

  const handleConfirm = async () => {
    await handleActiveUser(selectedUserId, selectedUserStatus, search)
    setConfirmOpen(false)
  }

  const handleToggleUser = (id, status) => {
    setSelectedUserId(id)
    setSelectedUserStatus(status)
    setConfirmOpen(true)
  }

  const handleActiveUser = async (id, status, password) => {
    const response = await UserService.updateStatusUser(id, {
      isActiveUser: status,
      password_user: password,
    })
    if (response.status === 200) {
      toast.success(
        `Usuário ${status ? 'ativado' : 'desativado'} com sucesso`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        },
        setUpdateUsers(!updateUsers),
      )
    } else {
      toast.error(
        `Ocorreu um erro ao ${status ? 'ativar' : 'desativar'} o usuário`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        },
      )
    }
  }

  useEffect(() => {
    console.log('selectedUser')
  }, [selectedUser])

  const renderHeader = (user) => {
    return (
      <>
        <UserActions>
          <p>{user.email_user}</p>
          {user.is_root_user && <AndroidLogo size={24} />}
          {user.is_active_user ? <StyledAble /> : <StyledDisable />}
        </UserActions>
        <UserActions>
          <StyledPencil size={24} onClick={() => handleOpenEdit(user)} />
          {user.is_active_user ? (
            <StyledDown onClick={() => handleToggleUser(user.id_user, false)} />
          ) : (
            <StyledUp onClick={() => handleToggleUser(user.id_user, true)} />
          )}
        </UserActions>
      </>
    )
  }

  const renderContent = (user) => {
    return <ListViewUserContacts user={user} />
  }

  useEffect(() => {
    const inicializeData = async (responseData) => {
      setData({
        title: 'Gerenciamento',
        accordion: false,
        create: (
          <StyledPlusCircle
            size={24}
            onClick={() => setOpenDialogUsers(true)}
          />
        ),
        filter: true,
        setSelected: setSelectedUser,
        itens: responseData.map((user) => ({
          header: renderHeader,
          content: renderContent,
          object: user,
          objectFilter: user.email_user,
          id: user.id_user,
        })),
      })
    }

    const getUsers = async () => {
      const response = await UserService.getUsers()
      await inicializeData(response.data)
      setUsersIsLoading(false)
    }
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUsers])

  if (usersIsLoading) {
    return (
      <Container>
        <Header>
          <BackButton onClick={() => setNavigation('configList')}>
            <CaretLeft
              size={32}
              weight="bold"
              style={{ marginTop: '0.5rem' }}
            />
          </BackButton>
          <Title>Usuários</Title>
        </Header>
        <BoxContainer className="loading">
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </BoxContainer>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft size={32} weight="bold" style={{ marginTop: '0.5rem' }} />
        </BackButton>
        <Title>Usuários</Title>
      </Header>
      <BoxContainer>
        <Listview data={data} />
      </BoxContainer>
      <DialogUsers
        open={openDialogUsers}
        onChangeOpen={setOpenDialogUsers}
        update={updateUsers}
        setUpdate={setUpdateUsers}
      />
      <DialogUsersEdit
        socket={socket}
        open={openDialogUsersEdit}
        onChangeOpen={setOpenDialogUsersEdit}
        user={user}
        update={updateUsers}
        setUpdate={setUpdateUsers}
      />
      <DialogConfirm
        open={confirmOpen}
        onChangeOpen={() => {
          setSearch('')
          setConfirmOpen(false)
        }}
        onConfirm={handleConfirm}
        title={selectedUserStatus ? 'Ativar Usuário' : 'Desativar Usuário'}
        description={`Tem certeza que deseja ${selectedUserStatus ? 'ativar' : 'desativar'} o usuário? Essa ação pode ser revertida.`}
        userStatus={selectedUserStatus}
        setSearch={setSearch}
        search={search}
      />
    </Container>
  )
}

export default Gerenciamento
