import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Bounce, toast } from 'react-toastify'
import Input from '../../../../../../../../shared/components/InputForm'
import {
  Form,
  Label,
  Container,
  Header,
  Title,
  DivButton,
  Button,
  ButtonCadastro,
} from './styles'
import { ErrorSpan } from '../../../Tags/components/DialogTags/styles'
import BotService from '../../../../../../../../shared/services/Botflux/BotService'
import Dialog from '../../../../../../../../shared/components/Dialog'

const DialogBotFlux = ({
  open,
  typebot,
  onChangeOpen,
  showIconClose = true,
  setUpdate,
}) => {
  const botFluxScheme = z.object({
    nameBotFlux: z.string().min(1, 'Nome é obrigatório'),
    descriptionBotFlux: z.string().min(1, 'Texto de ajuda é obrigatório'),
  })
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(botFluxScheme),
  })

  useEffect(() => {
    if (!open) {
      reset()
    } else if (typebot) {
      setValue('nameBotFlux', typebot.name_bot_flux || '')
      setValue('descriptionBotFlux', typebot.description_bot_flux || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const onSubmit = async (data) => {
    const response = await BotService.putBotflux(typebot.id_bot_flux, data)

    if (response.status === 200) {
      toast.success('Fluxo de Bot editado com sucesso!', {
        transition: Bounce,
        autoClose: 3000,
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setUpdate((prev) => !prev)
      onChangeOpen(false)
    } else {
      toast.error('Erro ao editar Fluxo de Bot', {
        transition: Bounce,
        autoClose: 3000,
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={handleCloseDialog}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Editar Fluxo de Bot</Title>
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>Nome do Fluxo de Typebot:</Label>
          <Input
            label="Nome"
            placeholder="Nome"
            error={errors.nameBotFlux?.message}
            {...register('nameBotFlux')}
          />
          <ErrorSpan $span={errors.nameBotFlux?.message}>
            {errors.nameBotFlux?.message}
          </ErrorSpan>

          <Label>Texto de ajuda do Fluxo de Typebot:</Label>
          <Input
            label="Texto de ajuda"
            placeholder="Texto de ajuda"
            error={errors.descriptionBotFlux?.message}
            {...register('descriptionBotFlux')}
          />
          <ErrorSpan $span={errors.descriptionBotFlux?.message}>
            {errors.descriptionBotFlux?.message}
          </ErrorSpan>
          <DivButton>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <ButtonCadastro type="submit">Editar</ButtonCadastro>
          </DivButton>
        </Form>
      </Container>
    </Dialog>
  )
}

export default DialogBotFlux
