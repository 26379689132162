import React, { useEffect, useState } from 'react'
import Dialog from '../../../../../../../../shared/components/Dialog'
import {
  Title,
  ButtonContainer,
  Button,
  StyledInputContainer,
  StyledPhrase,
  StyledInput,
  StyledButton,
  FileNameDisplay,
} from './styles'
import { getFileType } from '../../../../../../../../utils/format'

const ReplyMessageModal = ({
  open,
  onChangeOpen,
  message,
  sendMessage,
  setNewMessage,
}) => {
  const [replyMessage, setReplyMessage] = useState('')
  const [fileBase64, setFileBase64] = useState(null)
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState(null)

  useEffect(() => {
    if (replyMessage) {
      setNewMessage(replyMessage)
    }
    // eslint-disable-next-line
  }, [replyMessage])

  useEffect(() => {
    if (fileBase64) {
      setFileType(getFileType(fileBase64))
    }
  }, [fileBase64])

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        setFileBase64(e.target.result)
      }

      reader.readAsDataURL(file)
      setFileName(file.name)
    } else {
      setFileBase64(null)
      setFileName('')
    }
  }

  const handleSendMessage = () => {
    sendMessage(fileBase64, fileType, message.idMessage)
    setNewMessage('')
    setReplyMessage('')
    setFileBase64(null)
    setFileName('')
    onChangeOpen(false)
    setFileType(null)
  }

  return (
    <Dialog open={open} onChangeOpen={onChangeOpen}>
      <Title>Responder Mensagem</Title>
      <StyledPhrase>Respondendo a: {message.message}</StyledPhrase>

      <StyledInputContainer>
        <StyledInput
          type="text"
          placeholder="Digite sua resposta..."
          value={replyMessage}
          onChange={(e) => setReplyMessage(e.target.value)}
        />
      </StyledInputContainer>

      <StyledInputContainer>
        <input
          type="file"
          accept="image/*, application/pdf"
          onChange={(e) => {
            handleFileChange(e)
          }}
          id="file-upload"
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <StyledButton as="span">
            {fileBase64 ? 'Arquivo Selecionado' : 'Selecionar Arquivo'}
          </StyledButton>
        </label>
        {fileBase64 && (
          <FileNameDisplay>Arquivo anexado: {fileName}</FileNameDisplay>
        )}
      </StyledInputContainer>

      <ButtonContainer>
        <Button onClick={() => onChangeOpen(false)}>Cancelar</Button>
        <Button
          disabled={!replyMessage.trim() && !fileBase64}
          onClick={() => {
            handleSendMessage()
          }}
        >
          Responder
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default ReplyMessageModal
