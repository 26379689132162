import { useEffect, useState } from 'react'
import imageContato from '../../../../../../assets/images/imageContato'
import {
  extractImage,
  formatLastMessageTimeStamp,
  getTextColor,
  getFirstNChars,
  formatGradeMessage,
} from '../../../../../../utils/format'
import {
  StyledMenu,
  StyledMenuItem,
  PencilStyled,
} from '../../../Chat/components/Message/styles'
import {
  ArrowsClockwiseStyled,
  TagStyled,
  UserListStyled,
} from '../../../Chat/components/ContactSuperior/styles'
import {
  BlocoContact,
  DivRow,
  DivColumn,
  ContactImage,
  CountMessages,
  LastIcons,
  ImageIconWrapper,
  Div,
  ContainerTags,
  DivTag,
  TagAbbreviation,
  TextAbbreviation,
  SubTitle,
  ArchiveIconStyled,
  UnarchiveIconStyled,
  UserPlusStyled,
} from './styles'
import { useImage } from '../../../../../../hook/image'
import {
  ChatText,
  FileAudio,
  ImageSquare,
  FileText,
  VideoCamera,
} from 'phosphor-react'
import { useTriggers } from '../../../../../../hook/triggers'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import FileService from '../../../../../../shared/services/File/FileService'
import { StickerIcon } from '../../../Chat/components/Emoji/styles'
import AIService from '../../../../../../shared/services/AI/AIService'

const ContactLateral = ({
  contact,
  selectedContact,
  lastMessage,
  typeMessage,
  onContactClick,
  handleArchiveContact,
  typeSearch,
  setLateralContacts,
}) => {
  const { setIsOpenTags, setIsOpenUsers, setIsOpenLocal, searchedMsg } =
    useTriggers()
  const [unreadMessages, setUnreadMessages] = useState(
    contact.unreadMessageCount,
  )
  const { contactImage, setContactImage } = useImage()

  const [anchorEl, setAnchorEl] = useState(null)
  const openContext = Boolean(anchorEl)

  const handleContextMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const emoji = {
    1: '😡',

    2: '😠',

    3: '😤',

    4: '😒',

    5: '😐',

    6: '😊',

    7: '😍',

    8: '😎',

    9: '🤩',

    10: '🥳',
  }

  const handleSyncImage = async () => {
    try {
      const response = await ContactService.syncImageUser(contact.id)
      const filename = response.data.picture_filename_contact
      const filetype = response.data.picture_filetype_contact
      const resp = await FileService.getSrc(filename, filetype)
      setContactImage(contact.id, resp.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  const playBeep = () => {
    const beep = new Audio('/notification-sound.mp3')
    beep
      .play()
      .then(() =>
        // eslint-disable-next-line
        console.log('audio ok'))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    const notificationTimeout = setTimeout(() => {
      if (
        unreadMessages < contact.unreadMessageCount &&
        (selectedContact === null || selectedContact !== contact.id)
      ) {
        setUnreadMessages(contact.unreadMessageCount)
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              navigator.serviceWorker.ready.then((registration) => {
                if (document.visibilityState === 'hidden') {
                  registration.showNotification('Nova mensagem', {
                    body: 'nova mensagem',
                    icon: '/android/android-launchericon-192-192.png',
                    badge: '/android/android-launchericon-72-72.png',
                  })
                } else {
                  playBeep()
                }
              })
            }
          })
          .catch((error) => console.log('Erro ao solicitar permissão: ', error))
      }
    }, 300)

    return () => clearTimeout(notificationTimeout)
  }, [contact.unreadMessageCount, selectedContact, contact.id, unreadMessages])

  useEffect(() => {
    if (selectedContact === contact.id) {
      setUnreadMessages(0)
    }
  }, [selectedContact, contact.id])

  const renderTags = () => {
    if (!contact.tags) {
      return null
    }
    return (
      <DivTag>
        {contact.tags.map((tag) => (
          <TagAbbreviation key={tag.id_tag} $color={tag.color_hex_tag}>
            <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
              {tag.abbreviation_tag}
            </TextAbbreviation>
          </TagAbbreviation>
        ))}
      </DivTag>
    )
  }

  const messageIcons = {
    sticker: <StickerIcon style={{ width: '20px' }} />,
    chat: <ChatText style={{ width: '20px' }} />,
    audio: <FileAudio style={{ width: '20px' }} />,
    image: <ImageSquare style={{ width: '20px' }} />,
    document: <FileText style={{ width: '20px' }} />,
    video: <VideoCamera style={{ width: '20px' }} />,
    ptt: <FileAudio style={{ width: '20px' }} />,
  }

  const checkSelected = () => {
    if (typeSearch === 'contacts') {
      return selectedContact === contact.id ? 'true' : 'false'
    } else if (typeSearch === 'messages') {
      return searchedMsg.lastMessageId === contact.lastMessageId
        ? 'true'
        : 'false'
    }
  }

  if (contact) {
    return (
      <BlocoContact
        onClick={() => onContactClick(contact.id)}
        $isSelected={checkSelected()}
        onContextMenu={handleContextMenu}
        style={{ cursor: 'context-menu' }}
      >
        <DivRow>
          {extractImage(contactImage[contact.id]) !== 'null' ? (
            <ContactImage
              src={contactImage[contact.id]}
              alt="Imagem do contato"
            />
          ) : (
            <ContactImage src={imageContato} alt="Imagem do contato" />
          )}
          <DivColumn>
            <p>
              {contact.name}
              {'\t'}
              {emoji[contact.gradeContact]}
            </p>
            <Div>
              {lastMessage === '' && typeMessage === '' ? (
                <ImageIconWrapper>
                  <span>Nova Conversa</span>
                </ImageIconWrapper>
              ) : lastMessage === '' ? (
                <>
                  {messageIcons[typeMessage]}
                  <SubTitle>
                    {typeMessage === 'chat'
                      ? 'Nova mensagem'
                      : typeMessage === 'sticker'
                        ? 'Figurinha'
                        : 'Novo Arquivo'}
                  </SubTitle>
                </>
              ) : typeMessage === 'vcard' ? (
                <SubTitle>Contato: {lastMessage.split(';')[0]}</SubTitle>
              ) : (
                <>
                  {messageIcons[typeMessage]}
                  <SubTitle>
                    {typeMessage === 'audio'
                      ? 'Mensagem de áudio'
                      : lastMessage.length < 19
                        ? lastMessage.replace(/[*_~`]+/g, '')
                        : `${getFirstNChars(lastMessage, contact.name.length)}...`}
                  </SubTitle>
                </>
              )}
            </Div>
          </DivColumn>
          <ContainerTags>{renderTags()}</ContainerTags>
          <LastIcons>
            <p>{formatLastMessageTimeStamp(contact.lastMessageTimestamp)}</p>
            {unreadMessages &&
            !contact.isViewedMessage &&
            contact.isViewedMessage !== null ? (
              <CountMessages></CountMessages>
            ) : null}
          </LastIcons>
        </DivRow>

        <StyledMenu
          anchorEl={anchorEl}
          open={openContext}
          onClose={handleClose}
          onClick={handleClose}
        >
          <StyledMenuItem onClick={handleSyncImage}>
            Sincronizar imagem
            <ArrowsClockwiseStyled />
          </StyledMenuItem>
          {contact.isArchivedContact ? (
            <StyledMenuItem
              onClick={() => handleArchiveContact(contact.id, contact.name)}
            >
              Desarquivar contato
              <UnarchiveIconStyled />
            </StyledMenuItem>
          ) : (
            <StyledMenuItem
              onClick={() =>
                handleArchiveContact(
                  contact.id,
                  contact.name,
                  !contact.isArchivedContact,
                )
              }
            >
              Arquivar contato
              <ArchiveIconStyled />
            </StyledMenuItem>
          )}
          <StyledMenuItem onClick={() => setIsOpenTags(true)}>
            Atribuir tags
            <TagStyled />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setIsOpenUsers(true)}>
            Atribuir a usuário
            <UserListStyled />
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setIsOpenLocal(true)}>
            Editar contato
            <PencilStyled />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              AIService.getAvaliacao(contact.id)
                .then((resp) => {
                  console.log(resp)
                  const grade = formatGradeMessage(resp.data.message)
                  contact.gradeContact = emoji
                  setLateralContacts((prev) => {
                    return prev.map((element) => {
                      if (element.id === contact.id) {
                        return {
                          ...element,
                          gradeContact: grade,
                          summaryContact: resp.data.avaliacao,
                        }
                      }
                      return element
                    })
                  })
                })
                .catch((error) => console.log(error))
            }}
          >
            Avaliar contato
            <UserPlusStyled />
          </StyledMenuItem>
        </StyledMenu>
      </BlocoContact>
    )
  }
}

export default ContactLateral
