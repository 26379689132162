import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: ${({ theme }) => theme.colors.Text};
  background-color: ${({ theme }) => theme.colors.guto.BackgroundLogin};
`

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.guto.FundoLogin};
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.293px; /* 105.733% */
`

export const Subtitle = styled.h2`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 42.293px; /* 211.466% */

  @media (max-width: 370px) {
    font-size: 0.9rem;
  }

  @media (max-width: 340px) {
    font-size: 0.8rem;
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid
    ${({ $focused, $hovered, theme }) =>
      $focused ? theme.colors.guto.Azul1 : $hovered ? '#000' : '#a6a6a6'};
  border-radius: 12px;
  color: black;
  padding: 3px 8px;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  transition: border 0.3s;
  & input::placeholder {
    color: #a6a6a6;
  }
`

export const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
`

export const Label = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.Text};
  margin-bottom: 0.5rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.guto.BotaoLogin};
  color: white;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: opacity ease 0.3s;
  margin-top: 1rem;
  width: 40%;
  align-self: center;

  &:hover {
    opacity: 0.8;
  }
`

export const ErrorSpan = styled.span`
  position: absolute;
  color: red;
  font-size: 0.9rem;
  margin-top: 4.5rem;
`

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.guto.BackgroundLogin};
  @media (max-width: 800px) {
    display: none;
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    padding: 0 2rem;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const AStyled = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &.login {
    margin-bottom: 1rem;
    @media (min-width: 800px) {
      display: none;
    }
  }
`

export const MarkName = styled.h1`
  font-size: 5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1rem;
  @media (max-width: 1000px) {
    font-size: 3rem;
  }
`

export const CodeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1.4rem;
`

export const CodeInput = styled.input`
  width: 40px;
  height: 50px;
  font-size: 1.5rem;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`
