import Dialog from '../../../../../../../../shared/components/Dialog'
import {
  Container,
  Title,
  DivButton,
  ButtonCancel,
  ButtonCadastro,
  Header,
  Subtitle,
} from '../DialogContacts/styles'
import Input from '../../../../../../../../shared/components/Input/index.jsx'
import { useTheme } from 'styled-components'
import { useEffect, useState } from 'react'

const DialogConfirm = ({
  open,
  onChangeOpen,
  onConfirm,
  showIconClose = true,
  title,
  description,
  setSearch,
  search,
  userStatus,
}) => {
  const handleOnChengeOpen = () => {
    setSearch('')
    onChangeOpen()
  }

  const theme = useTheme()
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Dialog
      open={open}
      onChangeOpen={handleOnChengeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
        </Header>
        <Input
          type={'password'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Digite sua senha"
          style={{
            backgroundColor: theme.colors.guto.FundoAzul,
          }}
        ></Input>
        <DivButton>
          <ButtonCancel onClick={onChangeOpen}>Cancelar</ButtonCancel>
          <ButtonCadastro onClick={onConfirm}>
            {userStatus ? 'Ativar' : 'Desativar'}
          </ButtonCadastro>
        </DivButton>
      </Container>
    </Dialog>
  )
}

export default DialogConfirm
