import React from 'react'
import { ArrowsCounterClockwiseStyled, ButtonRevert, Container } from './styles'

const SoftDelete = ({ revert }) => {
  return (
    <Container>
      Alteracoes feitas.{' '}
      <ButtonRevert
        onClick={(e) => {
          e.stopPropagation()
          revert()
        }}
      >
        Reverter <ArrowsCounterClockwiseStyled />
      </ButtonRevert>
    </Container>
  )
}

export default SoftDelete
