import React from 'react'
import {
  BoxContainer,
  Container,
  Header,
  InputContainer,
  Separator,
  Title,
  TitleList,
} from './styles.js'
import Input from '../Input/index.jsx'
import {
  AccordionRoot,
  AccordionItem,
  AccordionTrigger,
  AccordionHeader,
  AccordionContent,
} from './components/ListviewComponents/index.jsx'

const Listview = ({ data }) => {
  const [search, setSearch] = React.useState('')

  const filterItens = (itens) => {
    if (search === '') return itens

    return itens.filter((item) => {
      return item.objectFilter.toLowerCase().includes(search.toLowerCase())
    })
  }

  return (
    <Container>
      <Header className={data.class ? data.class : null}>
        <TitleList className={data.class ? data.class : null}>
          {data.title}
        </TitleList>
        <InputContainer>
          <Input
            placeholder="Pesquisa"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={data.class ? data.class : null}
          />
          {data.create ? data.create : null}
        </InputContainer>
      </Header>
      <AccordionRoot type="multiple" collapsible>
        <BoxContainer className={data.class ? data.class : null}>
          {/* {data.itens.map((item) => (
            <>
              <AccordionItem key={item.id} value={item.id}>
                <AccordionHeader>
                  <AccordionTrigger>
                    {item.header(item.object)}
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent>
                  {item.content ? item.content(item.object) : null}
                </AccordionContent>
              </AccordionItem>
              <Separator />
            </>
          ))} */}
          {filterItens(data.itens).length === 0 ? (
            <Title
              className="notFound"
              style={{
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Nenhum item encontrado
            </Title>
          ) : (
            filterItens(data.itens).map((item) => (
              <div
                key={item.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AccordionItem value={data.accordion ? undefined : []}>
                  <AccordionHeader
                    onClick={() => {
                      data.setSelect(item.object)
                    }}
                  >
                    <AccordionTrigger
                      className={data.class ? data.class : null}
                    >
                      {item.header(item.object)}
                    </AccordionTrigger>
                  </AccordionHeader>
                  <AccordionContent>
                    {item.content ? item.content(item.object) : null}
                  </AccordionContent>
                </AccordionItem>
                <Separator />
              </div>
            ))
          )}
        </BoxContainer>
      </AccordionRoot>
    </Container>
  )
}

export default Listview
