import {
  Container,
  ContainerActions,
  ContainerTypebots,
  Header,
  ListTags,
  Separator,
  Title,
  Typebot,
  TypebotDescription,
  TypebotInitials,
  RocketLaunchStyled,
  DisableFluxBot,
} from './styles'
import {
  ContactNotFound,
  DivRowSupContacts,
  StyledFirst,
  StyledRefresh,
} from '../../../../../../../Settings/components/MainScreen/components/Gerenciamento/styles'
import Input from '../../../../../../../../shared/components/Input'
import Dialog from '../../../../../../../../shared/components/Dialog'
import BotService from '../../../../../../../../shared/services/Botflux/BotService'
import { useEffect, useState } from 'react'

import { Bounce, toast } from 'react-toastify'

const DialogTypebot = ({
  open,
  onChangeOpen,
  showIconClose = true,
  id,
  contact,
  lateralContacts,
  setLateralContacts,
}) => {
  const [searchTypebot, setSearchTypebot] = useState('')
  const [typebots, setTypebots] = useState([])
  const [update, setUpdate] = useState(false)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleTypebot = async (idBotflux) => {
    const response = await BotService.updateBotflux(idBotflux, id)
    if (response.status === 200) {
      toast.success('Botflux vinculado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    } else {
      toast.error('Erro ao vincular Botflux!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  useEffect(() => {
    const getTypebots = async () => {
      const response = await BotService.getBotfluxs()
      setTypebots(response.data)
    }
    getTypebots()
  }, [update])

  const filteredTypebots = typebots.filter((typebot) => {
    const name = typebot?.name_bot_flux?.toLowerCase()
    return name.includes(searchTypebot.toLowerCase())
  })

  const handleDisableTypebot = async () => {
    const res = await BotService.disableBotflux(id)
    if (res.status === 200) {
      toast.success('Botflux desvinculado com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    } else {
      toast.error('Erro ao desvincular Botflux!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  const renderTypebots = () => {
    if (filteredTypebots.length === 0) {
      return <ContactNotFound>Nenhum Typebot encontrado</ContactNotFound>
    }
    return filteredTypebots.map((typebot) => {
      return (
        <>
          <Typebot key={typebot.id_bot_flux}>
            <TypebotInitials>
              <p>
                <strong>ID:</strong> {typebot.id_bot_flux}
              </p>
              <p>
                <strong>Nome: </strong>
                {typebot.name_bot_flux}
              </p>
            </TypebotInitials>
            <TypebotDescription>
              <p>
                <strong>Texto de ajuda:</strong> {typebot.description_bot_flux}
              </p>
            </TypebotDescription>
            <ContainerActions>
              {typebot.is_first_bot_flux && <StyledFirst />}
              <RocketLaunchStyled
                onClick={() => handleTypebot(typebot.id_bot_flux)}
              />
            </ContainerActions>
          </Typebot>
          <Separator />
        </>
      )
    })
  }

  const handleRefresh = async () => {
    const res = await BotService.refreshBotflux()
    if (res.status === 200) {
      toast.success('Bots sincronizados com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      setUpdate(!update)
    } else {
      toast.error('Erro ao sincronizar Bots!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      title="Typebots"
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Fluxo de bots</Title>
          <DisableFluxBot onClick={() => handleDisableTypebot()}>
            Desvincular bot
          </DisableFluxBot>
        </Header>
        <ListTags>
          <DivRowSupContacts>
            <Input
              placeholder="Buscar Typebot"
              value={searchTypebot}
              onChange={(e) => setSearchTypebot(e.target.value)}
              className={'dialog'}
            />
            <StyledRefresh onClick={() => handleRefresh()} />
          </DivRowSupContacts>

          <ContainerTypebots>{renderTypebots()}</ContainerTypebots>
        </ListTags>
      </Container>
    </Dialog>
  )
}

export default DialogTypebot
