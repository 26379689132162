import { useEffect, useState } from 'react'
import {
  Container,
  ContainerTags,
  Header,
  ListTags,
  Separator,
  Title,
} from './styles'
import {
  ContactNotFound,
  DivRowSupContacts,
  StyledPlusCircle,
  StyledTrash,
} from '../../../../../../../Settings/components/MainScreen/components/Gerenciamento/styles'
import Input from '../../../../../../../../shared/components/Input'
import {
  Tag,
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from '../../../../../../../Settings/components/MainScreen/components/Tags/styles'
import DialogTag from './components/DialogAddTags'
import TagsService from '../../../../../../../../shared/services/Tags/TagsService'
import { getTextColor } from '../../../../../../../../utils/format'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../shared/components/Dialog'

const DialogTags = ({
  open,
  onChangeOpen,
  showIconClose = true,
  id,
  contact,
  lateralContacts,
  setLateralContacts,
}) => {
  const [tags, setTags] = useState([])
  const [searchTag, setSearchTag] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const filteredTags = tags.filter((tag) => {
    const name = tag?.name_tag?.toLowerCase()
    return name.includes(searchTag.toLowerCase())
  })

  useEffect(() => {
    const getTags = async () => {
      if (contact.tags) {
        setTags(contact.tags)
      } else {
        setTags([])
      }
    }
    getTags()
  }, [contact.tags])

  const handleDeleteTag = async (tag) => {
    const response = await TagsService.deleteContactTag(id, tag.id_tag)
    if (response) {
      toast.success('Tag removida com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      setTags(tags.filter((t) => t.id_tag !== tag.id_tag))
      setLateralContacts(
        lateralContacts.map((lateralContact) => {
          if (lateralContact.id === id) {
            return {
              ...lateralContact,
              tags: lateralContact.tags.filter(
                (lateralTag) => lateralTag.id_tag !== tag.id_tag,
              ),
            }
          }
          return lateralContact
        }),
      )
    } else {
      toast.error('Erro ao remover tag', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  const renderTags = () => {
    if (filteredTags.length > 0) {
      return filteredTags.map((tag) => {
        return (
          <>
            <Tag key={tag.id_tag}>
              <TagActions>
                <TagAbbreviation $color={tag.color_hex_tag}>
                  <TextAbbreviation
                    $color={() => getTextColor(tag.color_hex_tag)}
                  >
                    {tag.abbreviation_tag}
                  </TextAbbreviation>
                </TagAbbreviation>
                <TagText>{tag.name_tag}</TagText>
              </TagActions>
              <TagActions>
                <StyledTrash size={24} onClick={() => handleDeleteTag(tag)} />
              </TagActions>
            </Tag>
            <Separator />
          </>
        )
      })
    } else {
      return <ContactNotFound>Nenhuma tag encontrada</ContactNotFound>
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Tags</Title>
        </Header>
        <ListTags>
          <DivRowSupContacts>
            <Input
              placeholder="Pesquisar Tags"
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              className={'dialog'}
            />
            <StyledPlusCircle size={24} onClick={() => setOpenDialog(true)} />
          </DivRowSupContacts>
          <DialogTag
            open={openDialog}
            onChangeOpen={setOpenDialog}
            id={id}
            tagsSelecteds={tags}
            setTagsSelecteds={setTags}
            lateralContacts={lateralContacts}
            setLateralContacts={setLateralContacts}
          />
          <ContainerTags>{renderTags()}</ContainerTags>
        </ListTags>
      </Container>
    </Dialog>
  )
}

export default DialogTags
