import React from 'react'
import EmojiPicker from 'emoji-picker-react'
import {
  ButtonContainer,
  EmojiComponent,
  IconContainer,
  SmilyIcon,
  StickerIcon,
} from './styles'
import LocalStorage from '../../../../../../utils/LocalStorage'
import StickerLibrary from './components/SickerLibrary'
const EmojiModal = ({ isOpen, setNewMessage, handleSendMessage }) => {
  const emojiStyle = LocalStorage.getEmojiStyle()

  const [showEmojiPicker, setShowEmojiPicker] = React.useState('emoji')

  if (!isOpen) return null

  return (
    <EmojiComponent>
      <ButtonContainer>
        <IconContainer onClick={() => setShowEmojiPicker('emoji')}>
          <SmilyIcon $isChecked={showEmojiPicker === 'emoji'} />
        </IconContainer>
        <IconContainer onClick={() => setShowEmojiPicker('sticker')}>
          <StickerIcon $isChecked={showEmojiPicker === 'sticker'} />
        </IconContainer>
      </ButtonContainer>
      {showEmojiPicker === 'emoji' ? (
        <EmojiPicker
          locale="pt"
          width={window.innerWidth < 768 ? '80vw' : 'auto'}
          emojiStyle={emojiStyle}
          theme={'dark'}
          autoFocusSearch={true}
          onEmojiClick={(emojiData) => {
            setNewMessage((prev) => prev + emojiData.emoji)
          }}
        />
      ) : (
        <StickerLibrary handleSendMessage={handleSendMessage} />
      )}
    </EmojiComponent>
  )
}

export default EmojiModal
