import { ArrowsCounterClockwise } from 'phosphor-react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ButtonRevert = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  background-color: #ea3b3b;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  padding: 3px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  gap: 5px;
  &:hover {
    opacity: 0.8;
  }
`

export const ArrowsCounterClockwiseStyled = styled(ArrowsCounterClockwise)`
  color: white;
  size: 5px;
`
