import React, { useEffect, useRef, useState } from 'react'
import { toast, Bounce } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  ModalImageSection,
  ModalNameSection,
  // ModalNumberSection,
  ModalDescriptionSection,
  InputBox,
  InputLabel,
  ButtonGroup,
  NameContactEnter,
  // ReadMoreButton,
  ModalImage,
  EditButton,
  SyncButton,
  ArchiveButton,
  NameContact,
  DivColumn,
  ErrorMessage,
  ContainerEdit,
  ContainerPerfil,
  Number,
  ContainerPerfilEdit,
  ContainerDescription,
  ContainerIsOpenEdit,
  NotFoundSummary,
} from './styles'
import Dialog from '../../../../../../../../shared/components/Dialog'
import imageContato from '../../../../../../../../assets/images/imageContato'
import {
  formatarDataParaBrasil,
  extractImage,
} from '../../../../../../../../utils/format'
import { useTriggers } from '../../../../../../../../hook/triggers'
import SoftDelete from '../../../../../../../../shared/components/SoftDelete'

const DialogEdit = ({
  isOpen,
  onChangeOpen,
  contactImage,
  dataContact,
  contactDateEnter,
  loading,
  verifyIsRoot,
  handleSyncImage,
  isOpenEdit,
  nameContact,
  setNameContact,
  error,
  handleSave,
  editContact,
  editedNames,
  handleArchiveContact,
  expandedDescription,
  isLongDescription,
  toggleDescription,
  setEditedNames,
  setLateralContacts,
}) => {
  const isRevertingRef = useRef(false)

  // const MAX_DESCRIPTION_LENGTH = 150
  const { setIsOpenEdit } = useTriggers()
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const renderEnterDate = () => {
    if (verifyIsRoot()) {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Entrou:'}</span>
        </div>
      )
    } else {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Atribuído:'}</span>
        </div>
      )
    }
  }

  const checkNumberCharacters = (value) => {
    if (!value) {
      return false
    }

    if (value.length > 470 && isOpenEdit) {
      return true
    }

    if (value.length > 350 && !isOpenEdit) {
      return true
    }
    return false
  }

  const summaryContact = dataContact.summaryContact

  const handleNameOnFront = (name) => {
    setEditedNames((prevNames) => ({
      ...prevNames,
      [dataContact.id]: name,
    }))
    setLateralContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.id === dataContact.id ? { ...contact, name } : contact,
      ),
    )
  }

  const handleSaveWithRevert = () => {
    if (!nameContact.trim()) {
      toast.error('O nome não pode ficar em branco.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        transition: Bounce,
      })
      return
    }

    const currentName = nameContact
    const oldName = editedNames[dataContact.id] || dataContact.name

    setIsOpenEdit(false)
    handleNameOnFront(currentName)

    const handleRevert = () => {
      isRevertingRef.current = true
      setNameContact(oldName)
      handleNameOnFront(oldName)
      toast.dismiss()
      toast.success('Alterações revertidas.')
    }

    toast.info(<SoftDelete revert={handleRevert} />, {
      autoClose: 5000,
      hideProgressBar: true,
      onClose: () => {
        if (!isRevertingRef.current) {
          handleSave()
          toast.success('Alterações salvas com sucesso!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: 'colored',
            transition: Bounce,
          })
        }
        isRevertingRef.current = false
      },
    })
  }

  return (
    <Dialog open={isOpen} onChangeOpen={onChangeOpen} width={dialogWidth}>
      <ContainerEdit>
        <ContainerPerfil>
          <ModalImageSection>
            {extractImage(contactImage[dataContact.id]) !== 'null' ? (
              <ModalImage
                src={contactImage[dataContact.id]}
                alt="Imagem do contato"
              />
            ) : (
              <ModalImage src={imageContato} alt="Imagem do contato" />
            )}
          </ModalImageSection>
          <ContainerPerfilEdit>
            <ModalNameSection>
              {isOpenEdit ? (
                <ContainerIsOpenEdit>
                  <ButtonGroup>
                    <SyncButton onClick={handleSyncImage}>
                      Sincronizar Imagem
                    </SyncButton>
                    {dataContact.isArchivedContact ? (
                      <ArchiveButton
                        onClick={() => {
                          handleArchiveContact(dataContact.id, dataContact.name)
                        }}
                      >
                        Desarquivar contato
                      </ArchiveButton>
                    ) : (
                      <ArchiveButton
                        onClick={() => {
                          handleArchiveContact(
                            dataContact.id,
                            dataContact.name,
                            !dataContact.isArchivedContact,
                          )
                        }}
                      >
                        Arquivar contato
                      </ArchiveButton>
                    )}
                  </ButtonGroup>
                  <InputLabel>Nome</InputLabel>
                  <InputBox
                    maxLength={20}
                    value={nameContact}
                    onChange={(e) => setNameContact(e.target.value)}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </ContainerIsOpenEdit>
              ) : (
                <DivColumn>
                  <NameContact>
                    {editedNames[dataContact.id] ||
                      dataContact.name ||
                      'Nome Indisponível'}
                  </NameContact>
                </DivColumn>
              )}
            </ModalNameSection>
            <ModalNameSection>
              <Number>
                <p>{dataContact.number}</p>
              </Number>
              <NameContactEnter>
                {renderEnterDate()}
                <span>
                  {formatarDataParaBrasil(contactDateEnter.contactEnterDate)}
                </span>
              </NameContactEnter>
            </ModalNameSection>
            <EditButton
              onClick={isOpenEdit ? handleSaveWithRevert : editContact}
            >
              {isOpenEdit ? 'Salvar' : 'Editar'}
            </EditButton>
          </ContainerPerfilEdit>
        </ContainerPerfil>
        <ContainerDescription $overflow={checkNumberCharacters(summaryContact)}>
          <ModalDescriptionSection
            $overflow={checkNumberCharacters(summaryContact)}
          >
            <InputLabel>Descrição</InputLabel>
            {summaryContact ? (
              <p>{summaryContact}</p>
            ) : (
              <NotFoundSummary>Sem descrição ainda</NotFoundSummary>
            )}
            {/* {isLongDescription && summaryContact && (
              <ReadMoreButton onClick={toggleDescription}>
                {expandedDescription ? 'Ler menos' : 'Ler mais'}
              </ReadMoreButton>
            )} */}
          </ModalDescriptionSection>
        </ContainerDescription>
      </ContainerEdit>
    </Dialog>
  )
}

export default DialogEdit
