import styled from 'styled-components'

export const MyRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  z-index: 0;
`

export const MyAccordionItem = styled.div`
  margin-top: 1px;
  width: ${(p) => p.$width || '100%'};
  margin: 0.6rem 0;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:focus-within {
    position: relative;
    z-index: 1;
  }
`
export const MyHeader = styled.div`
  display: flex;
`

export const MyTrigger = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  > p {
    color: ${({ theme }) => theme.colors.Text};
  }
  color: ${({ theme }) => theme.colors.Text};
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  cursor: pointer;
  transition: border-radius 0.5s ease;
  margin-top: 5px;

  &[data-state='open'] {
    border-radius: 0.75rem 0.75rem 0 0;

    .iconDown {
      transform: rotate(180deg);
    }
    .open {
      border-radius: 0 0.75rem 0 0;
    }
  }

  &.contact {
    background-color: ${({ theme }) => theme.colors.guto.Azul2};
  }
`

export const MyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};

  .noFoundCategory {
    display: flex;
    justify-content: center;
    color: red;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  &[data-state='open'] {
    animation: slideDown 400ms ease-in-out;
  }

  &[data-state='closed'] {
    animation: slideUp 100ms ease-in-out;
  }
`

export const MyIconSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  height: 100%;
  width: 40px;
  margin-right: -5px;
  border-left: 1px solid gray;
  background: ${({ theme }) => theme.colors.guto.Azul1};
  border-radius: 0 0.75rem 0.75rem 0;
  transition: border-radius 0.5s ease;
`
