import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserService from '../../shared/services/User/UserService'
import LocalStorage from '../../utils/LocalStorage'
import { jwtDecode } from 'jwt-decode'

import {
  Container,
  Div,
  ContainerLogin,
  InputContainer,
  StyledInput,
  Label,
  Form,
  Title,
  Button,
  ErrorSpan,
  ContainerImg,
  Img,
  AStyled,
  LoginDiv,
  Subtitle,
} from './styles'

import { Lock } from 'phosphor-react'

const ChangePassword = () => {
  const [hovered, setHovered] = React.useState({
    password: false,
    confirmPassword: false,
  })
  const [focused, setFocused] = React.useState({
    password: false,
    confirmPassword: false,
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    if (!token) {
      console.log('Token não encontrado')
      window.location.href = '/'
    }
    LocalStorage.setValue('token', token)
  }, [])

  const passwordSchema = z
    .object({
      password: z.string().min(8, 'A senha deve ter pelo menos 8 caracteres'),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: 'As senhas não conferem',
      path: ['confirmPassword'],
    })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(passwordSchema),
  })

  const handleChangePassword = React.useCallback(async (data) => {
    try {
      const user = jwtDecode(LocalStorage.getValue('token'))
      const dataUser = {}

      if (user) {
        dataUser.emailUser = user.email
        dataUser.passwordUser = data.password
        dataUser.fullNameUser = user.fullName
        dataUser.isRootUser = user.isRoot
      } else {
        toast.error('Usuário não encontrado')
        return
      }

      const response = await UserService.updateUser(user.id, dataUser)

      if (response.status === 200) {
        toast.success('Senha alterada com sucesso! Redirecionando...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })

        setTimeout(() => {
          window.location.href = '/' // Altere para a página desejada
        }, 3000)
      } else {
        toast.error('Erro ao alterar senha', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    } catch (err) {
      console.log(err)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Div>
        <ContainerImg>
          <AStyled href="https://jit.technology/">
            <Img src="guto_login.png" alt="GutoBot" />
          </AStyled>
        </ContainerImg>
        <ContainerLogin>
          <AStyled className="login" href="https://jit.technology/">
            <Img src="guto_login.png" alt="GutoBot" />
          </AStyled>
          <LoginDiv>
            <Title>Alteração de senha</Title>
            <Subtitle>Digite sua nova senha.</Subtitle>
            <Form onSubmit={handleSubmit(handleChangePassword)}>
              <Label>Nova Senha</Label>
              <InputContainer
                $hovered={hovered.password}
                $focused={focused.password}
              >
                <Lock />
                <StyledInput
                  type="password"
                  placeholder="Digite sua nova senha"
                  {...register('password')}
                  onFocus={() => setFocused({ ...focused, password: true })}
                  onBlur={() => setFocused({ ...focused, password: false })}
                  onMouseEnter={() =>
                    setHovered({ ...hovered, password: true })
                  }
                  onMouseLeave={() =>
                    setHovered({ ...hovered, password: false })
                  }
                />
              </InputContainer>
              <ErrorSpan>{errors.password?.message}</ErrorSpan>

              <Label>Confirmar Senha</Label>
              <InputContainer
                $hovered={hovered.confirmPassword}
                $focused={focused.confirmPassword}
              >
                <Lock />
                <StyledInput
                  type="password"
                  placeholder="Confirme sua senha"
                  {...register('confirmPassword')}
                  onFocus={() =>
                    setFocused({ ...focused, confirmPassword: true })
                  }
                  onBlur={() =>
                    setFocused({ ...focused, confirmPassword: false })
                  }
                  onMouseEnter={() =>
                    setHovered({ ...hovered, confirmPassword: true })
                  }
                  onMouseLeave={() =>
                    setHovered({ ...hovered, confirmPassword: false })
                  }
                />
              </InputContainer>
              <ErrorSpan>{errors.confirmPassword?.message}</ErrorSpan>

              <Button type="submit">Alterar senha</Button>
            </Form>
          </LoginDiv>
        </ContainerLogin>
      </Div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </Container>
  )
}

export default ChangePassword
