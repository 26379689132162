import styled from 'styled-components'

export const MidiaListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.BaseAzul1};
  border-radius: 12px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.Text};
`

export const MidiaItem = styled.div`
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.FundoAzul};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
  position: relative;

  &:hover {
    transform: scale(1.05);
    border-color: ${({ theme }) => theme.colors.Azul1};
  }
`

export const MidiaDocument = styled.div`
  width: 100%;
  height: 100;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;

  a {
    margin-top: 8px;
    background-color: white;
    color: ${({ theme }) => theme.colors.guto.Azul1};
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.guto.Azul2};
      color: white;
    }
  }
`

// export const MidiaDocument = styled.div`
//   width: 100%;
//   height: 50px;
//   background-color: ${({ theme }) => theme.colors.guto.Azul1};
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px 15px;
//   border-radius: 8px;
//   font-weight: bold;
//   font-size: 14px;

//   a {
//     background-color: white;
//     color: ${({ theme }) => theme.colors.guto.Azul1};
//     padding: 5px 10px;
//     border-radius: 5px;
//     text-decoration: none;
//     font-weight: bold;
//     transition: background-color 0.2s ease-in-out;

//     &:hover {
//       background-color: ${({ theme }) => theme.colors.guto.Azul2};
//       color: white;
//     }
//   }
// `

export const MidiaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

export const MidiaVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

export const MidiaAudio = styled.audio`
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.FundoAzul} !important;
  padding: 5px;
`

export const LoadMoreButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 8px;
  transition: opacity 0.2s ease-in-out;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
`
