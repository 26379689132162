import { useState, useEffect } from 'react'
import LocalStorage from '../../../../../../utils/LocalStorage'
import { jwtDecode } from 'jwt-decode'
// import { CaretLeft } from 'phosphor-react'
import Dialog from './components/DialogUserEdit'
import imageContato from '../../../../../../assets/images/imageContato.js'
import FileService from '../../../../../../shared/services/File/FileService'
import UserService from '../../../../../../shared/services/User/UserService'
import {
  Container,
  ProfileContainer,
  Avatar,
  Info,
  Label,
  Value,
  EditButton,
  Header,
  BackButton,
  Title,
  // Header,
  // BackButton,
  // Title,
} from './styles'
import { CaretLeft } from 'phosphor-react'
import { BoxContainer } from '../Geral/styles.js'

const SeuPerfil = ({ setNavigation }) => {
  const token = LocalStorage.getValue('token')
  const [user, setUser] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [update, setUpdate] = useState(false)
  const [userPicture, setUserPicture] = useState(null)

  useEffect(() => {
    if (!token) return

    const decodedUser = jwtDecode(token)

    UserService.getUserById(decodedUser.id)
      .then((response) => {
        setUser(response.data)

        if (
          response.data.picture_filename_user &&
          response.data.picture_filetype_user
        ) {
          return FileService.getSrc(
            response.data.picture_filename_user,
            response.data.picture_filetype_user,
          )
        }
      })
      .then((imageResponse) => {
        if (imageResponse?.data?.base64) {
          setUserPicture(imageResponse.data.base64)
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar usuário:', error)
      })
  }, [token, update])

  if (!user) return <p>Carregando...</p>

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.5rem',
            }}
          />
        </BackButton>
        <Title>Seu Perfil</Title>
      </Header>
      <BoxContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProfileContainer>
          <Avatar src={userPicture || imageContato} alt="Foto do usuário" />
          <Info>
            <Label>Nome:</Label>
            <Value>{user.full_name_user}</Value>
          </Info>
          <Info>
            <Label>Email:</Label>
            <Value>{user.email_user}</Value>
          </Info>
          <Info>
            <Label>Administrador:</Label>
            <Value>{user.is_root_user ? 'Sim' : 'Não'}</Value>
          </Info>
          <EditButton onClick={() => setOpenEdit(true)}>
            Editar Perfil
          </EditButton>
        </ProfileContainer>
      </BoxContainer>

      {openEdit && (
        <Dialog
          open={openEdit}
          onChangeOpen={setOpenEdit}
          update={update}
          setUpdate={setUpdate}
          user={user}
          socket={null}
          seuPerfil={true}
        />
      )}
    </Container>
  )
}

export default SeuPerfil
