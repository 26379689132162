import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
} from './styles'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import TagsService from '../../../../../../../../shared/services/Tags/TagsService.js'
import ColorPicker from './Components/ColorPicker/index.jsx'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../shared/components/Dialog/index.jsx'

const DialogTags = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  selectedTag,
  setSelectedTag,
}) => {
  const tagScheme = z.object({
    nameTag: z.string().min(1, 'Nome é obrigatório'),
    abbreviationTag: z.string().min(1, 'Abreviacao é obrigatório'),
    colorHexTag: z.string().min(1, 'Cor é obrigatório'),
  })
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(tagScheme),
  })

  useEffect(() => {
    if (selectedTag) {
      setValue('nameTag', selectedTag.name_tag)
      setValue('abbreviationTag', selectedTag.abbreviation_tag)
      setValue('colorHexTag', selectedTag.color_hex_tag)
    }
  }, [selectedTag, setValue])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const onSubmit = async (data) => {
    if (selectedTag) {
      const response = await TagsService.updateTag(selectedTag.id_tag, data)
      if (response.status === 200) {
        toast.success('Tag atualizada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
        setSelectedTag(null)
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        toast.error('Erro ao atualizar tag', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    } else {
      const response = await TagsService.createTag(data)
      if (response.status === 201) {
        toast.success('Tag criada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        toast.error('Erro ao criar tag', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={handleCloseDialog}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>{selectedTag ? 'Editar Tag:' : 'Criar Tag:'}</Title>
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>Nome da Tag:</Label>
          <Input type="text" placeholder="Nome" {...register('nameTag')} />
          <ErrorSpan $span={!!errors.nameTag?.message}>
            {errors.nameTag?.message || 'a'}
          </ErrorSpan>

          <Label>Abreviação:</Label>
          <Input
            type="text"
            placeholder="Abreviação"
            {...register('abbreviationTag')}
          />
          <ErrorSpan $span={!!errors.abbreviationTag?.message}>
            {errors.abbreviationTag?.message || 'a'}
          </ErrorSpan>

          <Label>Cor:</Label>
          <ColorPicker
            setValue={setValue}
            name="colorHexTag"
            value={selectedTag?.color_hex_tag}
          />

          <DivButton>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <ButtonCadastro type="submit">
              {selectedTag ? 'Atualizar' : 'Cadastrar'}
            </ButtonCadastro>
          </DivButton>
        </Form>
      </Container>
    </Dialog>
  )
}

export default DialogTags
