import React, { useEffect, useState } from 'react'

import {
  Title,
  Content,
  ListContent,
  ListContentTitle,
  ListContentText,
  Container,
  TextAreaStyled,
  Header,
  ButtonDownload,
} from './styles'
import {
  formatDateWithYear,
  formatTimeSchedule,
} from '../../../../../../../../utils/format'
import FileService from '../../../../../../../../shared/services/File/FileService'
import {
  ContainerImgPreview,
  ImgPreview,
  LoadingContainer,
  VideoPreview,
} from '../DialogAddSchedules/styles'
import { AudioPlayerStyled } from '../../../../../../../Home/components/Chat/styles'
import LoadingSpinner from '../../../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import Dialog from '../../../../../../../../shared/components/Dialog'

const DialogSchedule = ({
  open,
  onChangeOpen,
  schedule,
  setSchedule,
  typeMessageOptions,
  showIconClose = true,
}) => {
  const [mediaSrc, setMediaSrc] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const theme = useTheme()
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '90vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getBase64Media = async (filename, filetype) => {
    try {
      const response = await FileService.getSrc(filename, filetype)
      setMediaSrc(response.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getMedia = async () => {
      if (schedule?.file_name_message_schedule) {
        await getBase64Media(
          schedule?.file_name_message_schedule,
          schedule?.file_type_message_schedule,
        )
      }
    }

    const executeEffect = async () => {
      if (schedule) {
        await getMedia()
        setLoading(false)
      }
    }

    executeEffect()

    // eslint-disable-next-line
  }, [schedule])

  const renderMsg = () => {
    if (schedule?.content_message_schedule) {
      return (
        <ListContent style={{ alignItems: 'start' }}>
          <ListContentTitle>Mensagem:</ListContentTitle>
          <TextAreaStyled value={schedule.content_message_schedule} readOnly />
        </ListContent>
      )
    }
  }

  const renderMedia = () => {
    if (schedule?.type_message_schedule === 'image') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Imagem:
            </ListContentTitle>
            <ContainerImgPreview>
              <ImgPreview src={mediaSrc} />
            </ContainerImgPreview>
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    if (
      schedule?.type_message_schedule === 'audio' ||
      schedule?.type_message_schedule === 'ptt'
    ) {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Áudio:
            </ListContentTitle>
            <AudioPlayerStyled src={mediaSrc} controls />
          </ListContent>
        </>
      )
    }

    if (schedule?.type_message_schedule === 'video') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Vídeo:
            </ListContentTitle>
            <VideoPreview src={mediaSrc} controls />
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    if (schedule?.type_message_schedule === 'document') {
      return (
        <>
          <ListContent>
            <ListContentTitle style={{ alignItems: 'start' }}>
              Documento:
            </ListContentTitle>
            <ButtonDownload
              href={mediaSrc}
              target="_blank"
              rel="noreferrer"
              download={schedule?.file_name_message_schedule}
            >
              Baixar
            </ButtonDownload>
          </ListContent>
          {renderMsg()}
        </>
      )
    }

    return renderMsg()
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Detalhes do agendamento:</Title>
        </Header>
        {loading ? (
          <LoadingContainer>
            <LoadingSpinner strokeColor={theme.colors.Text} />
          </LoadingContainer>
        ) : (
          <Content>
            {schedule?.schedule_contacts.length > 0 && (
              <ListContent>
                <ListContentTitle>
                  {schedule?.schedule_contacts.length > 1
                    ? 'Contatos de destino:'
                    : 'Contato de destino:'}
                </ListContentTitle>
                <ListContentText>
                  {schedule?.schedule_contacts
                    .map((contact) => contact.full_name_contact)
                    .join(', ')}
                </ListContentText>
              </ListContent>
            )}

            {schedule?.schedule_tags.length > 0 && (
              <ListContent>
                <ListContentTitle>
                  {schedule?.schedule_tags.length > 1
                    ? 'Tags de destino'
                    : 'Tag de destino'}
                </ListContentTitle>
                <ListContentText>
                  {schedule?.schedule_tags
                    .map((tag) => tag.name_tag)
                    .join(', ')}
                </ListContentText>
              </ListContent>
            )}
            {/* {schedule?.to_contact_message_schedule && (
                      <ListContent>
                        <ListContentTitle>Contato de destino:</ListContentTitle>
                        <ListContentText>
                          {schedule?.to_contact_message_schedule?.split('@')[0]}
                        </ListContentText>
                      </ListContent>
                    )} */}
            <ListContent>
              <ListContentTitle>Tipo de mensagem:</ListContentTitle>
              <ListContentText>
                {typeMessageOptions[schedule?.type_message_schedule]}
              </ListContentText>
            </ListContent>
            <ListContent>
              <ListContentTitle>Status:</ListContentTitle>
              <ListContentText>
                {schedule?.is_processed_schedule ? 'Enviado' : 'Pendente'}
              </ListContentText>
            </ListContent>
            <ListContent>
              <ListContentTitle>Hora:</ListContentTitle>
              <ListContentText>
                {formatTimeSchedule(schedule?.timestamp_schedule)}
              </ListContentText>
              <ListContentTitle>Data:</ListContentTitle>
              <ListContentText>
                {formatDateWithYear(schedule?.timestamp_schedule)}
              </ListContentText>
            </ListContent>
            {renderMedia()}
          </Content>
        )}
      </Container>
    </Dialog>
  )
}

export default DialogSchedule
