import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
  Checkbox,
  CheckboxContainer,
  ModalImage,
  ModalImageContainer,
  FirstContainer,
  ContainerName,
} from './styles'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import UserService from '../../../../../../../../shared/services/User/UserService.js'
import * as z from 'zod'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../shared/components/Dialog/index.jsx'

const DialogUsers = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
}) => {
  const userScheme = z.object({
    fullNameUser: z.string().min(1, 'Nome é obrigatório'),
    emailUser: z.string().email('Email inválido').min(1, 'Email é obrigatório'),
    passwordUser: z.string().min(8, 'A senha deve ter no mínimo 8 caracteres'),
    isRootUser: z.boolean(),
  })

  const [image, setImage] = useState(null)
  const [error, setError] = useState('')
  const [preview, setPreview] = useState(null)
  const fileInputRef = useRef(null)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(userScheme),
  })

  useEffect(() => {
    if (!open) {
      reset()
      setImage(null)
      setError(null)
      setPreview(null)
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const handleImageChange = (e) => {
    const file = e.target.files[0]

    const validTypes = ['image/jpeg', 'image/png', 'image/gif']
    const maxSize = 2 * 1024 * 1024 // 2MB

    if (file && !validTypes.includes(file.type)) {
      setError('Apenas imagens nos formatos JPEG, PNG ou GIF são permitidas.')
      setImage(null)
      return
    }

    if (file && file.size > maxSize) {
      setError('O tamanho da imagem não pode exceder 2MB.')
      setImage(null)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
    setPreview(URL.createObjectURL(file))
    setError('')
  }

  const onSubmit = async (data) => {
    data = {
      ...data,
      userImage: image,
    }
    if (!error) {
      const response = await UserService.createUser(data)
      if (response.status === 201) {
        toast.success('Usuário cadastrado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
        setUpdate(!update)
        onChangeOpen(false)
      } else {
        toast.error('Erro ao cadastrar usuário', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    }
  }

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0]
  //   if (selectedFile) {
  //     setFile(selectedFile)
  //   }
  // }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Criar usuário:</Title>
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Label>Foto de usuário:</Label>
          <Input type="file" ref={fileInputRef} onChange={handleImageChange} />
          <ErrorSpan $span={error}>{error || 'a'}</ErrorSpan>
          {preview && (
            <ModalImageContainer>
              <ModalImage src={preview} alt="Preview da imagem selecionada" />
            </ModalImageContainer>
          )}

          <FirstContainer>
            <ContainerName>
              <Label>Nome:</Label>
              <Input
                type="text"
                placeholder="Nome"
                {...register('fullNameUser')}
              />
              <ErrorSpan $span={!!errors.fullNameUser?.message}>
                {errors.fullNameUser?.message || 'a'}
              </ErrorSpan>
            </ContainerName>
            <CheckboxContainer>
              <Label>É Administrador?</Label>
              <Checkbox type="checkbox" {...register('isRootUser')} />
            </CheckboxContainer>
          </FirstContainer>

          <Label>Email:</Label>
          <Input type="email" placeholder="Email" {...register('emailUser')} />
          <ErrorSpan $span={!!errors.emailUser?.message}>
            {errors.emailUser?.message || 'a'}
          </ErrorSpan>

          <Label>Senha:</Label>
          <Input
            type="password"
            placeholder="Senha"
            {...register('passwordUser')}
          />
          <ErrorSpan $span={!!errors.passwordUser?.message}>
            {errors.passwordUser?.message || 'a'}
          </ErrorSpan>

          <DivButton>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
          </DivButton>
        </Form>
      </Container>
    </Dialog>
  )
}

export default DialogUsers
