import { Container, InputStyled } from './styles'

const Input = ({ type, placeholder, className, ...res }) => {
  return (
    <Container>
      <InputStyled
        type={type}
        placeholder={placeholder}
        className={className}
        {...res}
      />
    </Container>
  )
}

export default Input
