import React, { useEffect } from 'react'
import {
  Container,
  Header,
  Title,
  Subtitle,
  ContainerConfig,
  SelectTrigger,
  SelectContent,
  SelectItem,
  Box,
  BoxContainer,
  Separator,
} from './styles.js'
import {
  Root as SelectRoot,
  Value as SelectValue,
  ItemText as SelectItemText,
  Icon as SelectIcon,
} from '@radix-ui/react-select'
import LocalStorage from '../../../../../../utils/LocalStorage.js'
import { BackButton } from '../../styles.js'
import { CaretLeft } from 'phosphor-react'

const Geral = ({ setNavigation }) => {
  const [emojiStyle, setEmojiStyle] = React.useState()

  useEffect(() => {
    setEmojiStyle(LocalStorage.getEmojiStyle())
  }, [])

  const handleEmonjiStyleChange = (value) => {
    setEmojiStyle(value)
    LocalStorage.setEmojiStyle(value)
  }

  const formatEmojiStyle = {
    native: 'Nativo',
    twitter: 'Twitter',
    google: 'Google',
    apple: 'Apple',
    facebook: 'Facebook',
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.5rem',
            }}
          />
        </BackButton>
        <Title>Geral</Title>
      </Header>
      <BoxContainer>
        <Box>
          <ContainerConfig>
            <Subtitle>Estilo de emoji:</Subtitle>
            <SelectRoot onValueChange={handleEmonjiStyleChange}>
              <SelectTrigger>
                <SelectValue placeholder={formatEmojiStyle[emojiStyle]} />
                <SelectIcon />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="native">
                  <SelectItemText>Nativo</SelectItemText>
                </SelectItem>
                <SelectItem value="twitter">
                  <SelectItemText>Twitter</SelectItemText>
                </SelectItem>
                <SelectItem value="google">
                  <SelectItemText>Google</SelectItemText>
                </SelectItem>
                <SelectItem value="apple">
                  <SelectItemText>Apple</SelectItemText>
                </SelectItem>
                <SelectItem value="facebook">
                  <SelectItemText>Facebook</SelectItemText>
                </SelectItem>
              </SelectContent>
            </SelectRoot>
          </ContainerConfig>
        </Box>
        <Separator />
      </BoxContainer>
    </Container>
  )
}

export default Geral
