import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
} from './styles'
import { ErrorSpan } from '../../../DialogUsers/styles.js'
import Input from '../../../../../../../../../../shared/components/InputForm/index.jsx'
import { formatPhoneNumber } from '../../../../../../../../../../utils/format.js'
import ContactService from '../../../../../../../../../../shared/services/Contact/ContactService.js'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../../../shared/components/Dialog/index.jsx'
import CustomPhoneInput from './components/InputInternacional/index.jsx'

const DialogCadastro = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
}) => {
  const [formData, setFormData] = useState({
    fullNameContact: '',
    idContact: '',
  })

  const [errors, setErrors] = useState({
    fullNameContact: '',
    idContact: '',
  })

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  useEffect(() => {
    if (!open) {
      setFormData({ fullNameContact: '', idContact: '' })
      setErrors({ fullNameContact: '', idContact: '' })
    }
  }, [open])

  const validateForm = () => {
    let isValid = true
    const newErrors = { fullNameContact: '', idContact: '' }

    if (formData.fullNameContact.trim() === '') {
      newErrors.fullNameContact = 'O nome é obrigatório'
      isValid = false
    }

    if (formData.idContact.length < 10 || formData.idContact.includes('_')) {
      newErrors.idContact = 'O número está incompleto'
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    const formattedData = {
      ...formData,
      idContact: formatPhoneNumber(formData.idContact),
    }

    const res = await ContactService.storeContact(formattedData)
    if (res.status === 201) {
      toast.success('Contato cadastrado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      ContactService.syncImageUser(formattedData.idContact)
      setUpdate(!update)
      handleCloseDialog()
    } else {
      toast.error('Erro ao cadastrar contato', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
    >
      <Container>
        <Header>
          <Title>Criar contato:</Title>
        </Header>
        <Form onSubmit={onSubmit}>
          <Label>Nome:</Label>
          <Input
            value={formData.fullNameContact}
            onChange={(e) =>
              setFormData({ ...formData, fullNameContact: e.target.value })
            }
            placeholder="Digite o nome"
            type="text"
          />
          <ErrorSpan $span={!!errors.fullNameContact}>
            {errors.fullNameContact || 'a'}
          </ErrorSpan>

          <Label>Telefone:</Label>
          <CustomPhoneInput
            onChange={(value) => setFormData({ ...formData, idContact: value })}
          />

          <ErrorSpan $span={!!errors.idContact}>
            {errors.idContact || 'a'}
          </ErrorSpan>

          <DivButton>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
          </DivButton>
        </Form>
      </Container>
    </Dialog>
  )
}

export default DialogCadastro
