import { RocketLaunch } from 'phosphor-react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const NameEmailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Email = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 10px;

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`

export const ListTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SeparatorOnFundoAzul};
  align-self: center;
`

export const ContainerTypebots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 0 0 0 0.2rem;
`

export const Title = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.293px; /* 105.733% */
  color: ${({ theme }) => theme.colors.Text};
`

export const DisableFluxBot = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Vermelho};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const Typebot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.Text};
  width: 95%;
  align-self: center;
`

export const TypebotInitials = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`

export const TypebotDescription = styled.p`
  font-size: 0.8rem;
  width: 33%;
  display: flex;
  text-align: justify;
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const RocketLaunchStyled = styled(RocketLaunch)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`
