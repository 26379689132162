import styled from 'styled-components'
import { PaperPlaneRight, Trash } from 'phosphor-react'

export const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  color: ${({ theme }) => theme.colors.Text};
  width: 25vw;
  max-height: 50vh;
  border-radius: 8px;

  @media (max-width: 1200px) {
    width: 30vw;
  }

  @media (max-width: 800px) {
    width: 50vw;
    height: 50vh;
  }

  @media (max-width: 500px) {
    width: 70vw;
    height: 50vh;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.White};
  margin-bottom: 20px;
`

export const StickerList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Ajuste para garantir colunas */
  gap: 10px;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 600px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Força 2 colunas em telas menores */
  }
`

export const StickerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.Separator};
  border-radius: 8px;
  padding-top: 8px;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 9vw;

  @media (max-width: 1200px) {
    width: 12vw;
  }

  @media (max-width: 800px) {
    width: 18vw;
  }

  @media (max-width: 500px) {
    width: 26vw;
  }
`

export const StickerImage = styled.img`
  width: 8vw;
  object-fit: cover;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 20vw;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  gap: 5px;
  width: 100%;
`

export const LoadingMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.SubText};
`

export const ErrorMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.Alert};
`

export const PaperPlaneIcon = styled(PaperPlaneRight)`
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.colors.Text};
`

export const TrashIcon = styled(Trash)`
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.colors.guto.Vermelho};
`
