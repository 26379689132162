import AIApi from './api/AIApi'

class AIService {
  async getAvaliacao(id) {
    try {
      return await AIApi.getAvaliacao(id)
    } catch (error) {
      return error
    }
  }
}

export default new AIService()
