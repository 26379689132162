import styled from 'styled-components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import { CaretLeft, Tag, UserList, ArrowsClockwise } from 'phosphor-react'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 10vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
`

export const ContactImage = styled.img`
  width: auto;
  height: 80%;
  border-radius: 50%;
  background-color: white;
  margin: 0.5rem;
  z-index: 3;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
`

export const SyncButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const ArchiveButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
`

export const NameContact = styled.h1`
  font-size: 1.5rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.Text};
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  gap: 0.5rem;
`

export const ModalImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
`

export const EditButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
`

export const ModalImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalNameSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  max-height: ${({ $overflow }) => ($overflow ? '100px' : '100%')};

  p {
    font-size: 1rem;
    text-align: justify;
    word-break: break-word;
  }
`

export const NameContactEnter = styled.div`
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.Text};
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  p,
  span {
    font-size: 0.9rem;
  }
`

export const ModalNumberSection = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.Text};
`

export const InputField = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  width: 200px;
  text-align: center;
`

export const InputLabel = styled.label`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  margin-bottom: 5px;
  display: block;
`

export const InputBox = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  color: ${({ theme }) => theme.colors.Text};
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  transition: all 0.3s;
`

export const MoreVertIconStyled = styled(MoreVertIcon)`
  font-size: large;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const SearchIconStyled = styled(SearchIcon)`
  font-size: large;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
`

export const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const CaretLeftStyled = styled(CaretLeft)`
  display: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  font-size: large;

  @media (max-width: 800px) {
    display: flex;
  }
`

export const UserListStyled = styled(UserList)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const TagStyled = styled(Tag)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ArrowsClockwiseStyled = styled(ArrowsClockwise)`
  font-size: large;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 0.8rem;
  font-weight: 500;
`

export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  margin-top: 0.25rem;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none; /* Remover a borda de foco padrão para melhor estilo */
  }
`

export const ContainerEdit = styled.div`
  display: grid;
  grid-template-columns: 40% 60%; /* Duas colunas do mesmo tamanho */
  gap: 0.5rem;
  width: 100%;
  height: auto; /* Ajusta conforme o conteúdo */

  @media (max-width: 1200px) {
    grid-template-columns: 1fr; /* Uma
    coluna */
  }
`

export const ContainerPerfil = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Ajusta à altura do grid */
`

export const ContainerPerfilEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 1rem;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  color: ${({ theme }) => theme.colors.Text};
`

export const Number = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.Text};
  align-self: center;
`

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: ${({ $overflow }) => ($overflow ? '12px 0 0 12px' : '12px')};
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  color: ${({ theme }) => theme.colors.Text};
  width: 100%;
  height: 100%; /* Mantém o mesmo tamanho do ContainerPerfil */
  overflow: auto; /* Permite rolagem caso o conteúdo seja maior que a altura */
  max-height: 100%; /* Evita que cresça mais que a altura definida */
`

export const ContainerIsOpenEdit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 12px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  color: ${({ theme }) => theme.colors.Text};
`

export const ContainerSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 12px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  color: ${({ theme }) => theme.colors.Text};
  max-height: ${({ $overflow }) => ($overflow ? '100%' : '100px')};
`

export const NotFoundSummary = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.guto.Vermelho};
  text-align: center;
`
