import { useCallback, useEffect, useState, useRef } from 'react'
import {
  Header,
  Title,
  Subtitle,
  ListContacts,
  DivButton,
  NoContacts,
  Button,
  Contact,
  XCircleStyled,
} from './styles.js'
import Input from '../../../../../../../../../../shared/components/Input/index.jsx'
import TagsService from '../../../../../../../../../../shared/services/Tags/TagsService.js'
import {
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from '../../../../../../../../../Settings/components/MainScreen/components/Tags/styles.js'
import { getTextColor } from '../../../../../../../../../../utils/format.js'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../../../shared/components/Dialog'
import SoftDelete from '../../../../../../../../../../shared/components/SoftDelete'

const DialogTag = ({
  id,
  open,
  onChangeOpen,
  showIconClose = true,
  tagsSelecteds,
  setTagsSelecteds,
  lateralContacts,
  setLateralContacts,
}) => {
  const [searchTags, setSearchTags] = useState('')
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const isRevertingRef = useRef(false)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const getTags = async () => {
      const response = await TagsService.getTags()
      if (response.data) {
        setTags(
          response.data.filter(
            (tag) =>
              !tagsSelecteds.find(
                (userSelected) => userSelected.id_tag === tag.id_tag,
              ),
          ),
        )
      }
    }
    getTags()
  }, [tagsSelecteds])

  const handleSelectTag = (tag) => {
    const index = selectedTags.findIndex(
      (selectedTag) => selectedTag.id_tag === tag.id_tag,
    )
    if (index === -1) {
      setSelectedTags([...selectedTags, tag])
    } else {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag.id_tag !== tag.id_tag),
      )
    }
  }

  const handleCloseDialog = () => {
    onChangeOpen(false)
    setSelectedTags([])
  }

  const filteredTags = () => {
    if (searchTags) {
      return tags.filter((tag) => {
        const name = tag?.name_tag?.toLowerCase()
        const abbreviation = tag?.abbreviation_tag?.toLowerCase()
        const search = searchTags.toLowerCase()
        if (tags.length === 0) return false
        return name.includes(search) || abbreviation.includes(search)
      })
    } else {
      return tags
    }
  }

  const verifySelectedTag = useCallback(
    (tag) =>
      selectedTags.some((selectedTag) => selectedTag.id_tag === tag.id_tag),
    [selectedTags],
  )

  const handleOnFront = (tagsToAdd) => {
    setTagsSelecteds((prev) => [...prev, ...tagsToAdd])
    setLateralContacts((prev) =>
      prev.map((contact) =>
        contact.id === id
          ? { ...contact, tags: [...(contact.tags || []), ...tagsToAdd] }
          : contact,
      ),
    )
  }

  const handleRevert =
    (previousTagsSelecteds, previousLateralContacts, tagsToRemove) => () => {
      isRevertingRef.current = true
      setTagsSelecteds(previousTagsSelecteds)
      setLateralContacts(previousLateralContacts)

      toast.dismiss()
      toast.success('Adição de tags revertida.', { transition: Bounce })
    }

  const handleSave = async () => {
    const previousTagsSelecteds = [...tagsSelecteds]
    const previousLateralContacts = lateralContacts.map((contact) => ({
      ...contact,
    }))

    handleOnFront(selectedTags)

    toast.info(
      <SoftDelete
        revert={handleRevert(
          previousTagsSelecteds,
          previousLateralContacts,
          selectedTags,
        )}
      />,
      {
        autoClose: 5000,
        hideProgressBar: true,
        onClose: async () => {
          if (!isRevertingRef.current) {
            const data = {
              userIDs: [id],
              tagsIDs: selectedTags.map((tag) => tag.id_tag),
            }
            const response = await TagsService.createContactTag(data)
            if (response.status === 201) {
              toast.success('Tags salvas com sucesso!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                transition: Bounce,
              })
            }

            if (!response.data) {
              setTagsSelecteds(previousTagsSelecteds)
              setLateralContacts(previousLateralContacts)
              toast.error('Erro ao adicionar tag.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
                transition: Bounce,
              })
            }
          }
          isRevertingRef.current = false
          setSelectedTags([])
        },
      },
    )
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Header>
        <Title>Adicionar Tags</Title>
        <Subtitle>Selecione as tags que deseja adicionar</Subtitle>
      </Header>
      <ListContacts>
        <Input
          placeholder="Pesquisar tag"
          value={searchTags}
          onChange={(e) => setSearchTags(e.target.value)}
        />
        {filteredTags().map((tag) => (
          <Contact
            key={tag.id_tag}
            onClick={() => handleSelectTag(tag)}
            $isSelected={verifySelectedTag(tag)}
          >
            <TagActions>
              <TagText>{tag.name_tag}</TagText>
              <TagText>-</TagText>
              <TagAbbreviation $color={tag.color_hex_tag}>
                <TextAbbreviation
                  $color={() => getTextColor(tag.color_hex_tag)}
                >
                  {tag.abbreviation_tag}
                </TextAbbreviation>
              </TagAbbreviation>
            </TagActions>
            <TagActions>
              {verifySelectedTag(tag) && <XCircleStyled />}
            </TagActions>
          </Contact>
        ))}
        {filteredTags().length === 0 && (
          <NoContacts>Nenhuma tag encontrada</NoContacts>
        )}
      </ListContacts>
      <DivButton>
        <Button onClick={handleCloseDialog}>Cancelar</Button>
        <Button
          onClick={() => {
            handleSave()
            handleCloseDialog()
          }}
        >
          Adicionar
        </Button>
      </DivButton>
    </Dialog>
  )
}

export default DialogTag
