import React from 'react'
import Listview from '../../../../../../../../shared/components/Listview'
import ContactService from '../../../../../../../../shared/services/Contact/ContactService'
import DialogContact from '../DialogContacts'
import DialogDelete from '../../../../../../../Home/components/Chat/components/ContactSuperior/components/DialogUsers/components/DialogDelete'
import {
  ContactContainer,
  ContactInfos,
  ContactName,
  ContactNumber,
  StyledPlusCircle,
  StyledTrash,
  UserActions,
} from '../../styles'
import {
  formatPhoneNumber,
  formatPhoneNumber2,
} from '../../../../../../../../utils/format'
// import { useToast } from '../../../../../../../../hook/toast'

const ListViewUserContacts = (user) => {
  const [usersContactsIsLoading, setUsersContactsIsLoading] =
    React.useState(true)
  const [data, setData] = React.useState(null)
  const [openDialogContacts, setOpenDialogContacts] = React.useState(false)
  const [updateContacts, setUpdateContacts] = React.useState(false)
  const [contacts, setContacts] = React.useState([])
  const [userDelete, setUserDelete] = React.useState({})
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false)
  // const { addToast } = useToast()

  const handleDeleteUser = (user) => {
    setUserDelete(user)
    setOpenDialogDelete(true)
  }

  const renderHeader = (contact) => {
    return (
      <ContactContainer>
        <ContactInfos>
          <ContactName>{contact.full_name_contact}</ContactName>
          <ContactNumber>
            {formatPhoneNumber2(contact.id_contact)}
          </ContactNumber>
        </ContactInfos>
        <UserActions>
          <StyledTrash
            onClick={() => handleDeleteUser(contact)}
            title="Deletar contato"
          />
        </UserActions>
      </ContactContainer>
    )
  }

  React.useEffect(() => {
    const inicializeData = async (responseData) => {
      setData({
        title: 'Contatos do Usuário',
        accordion: true,
        filter: true,
        class: 'contact',
        create: (
          <StyledPlusCircle onClick={() => setOpenDialogContacts(true)} />
        ),
        itens: responseData.map((contact) => ({
          id: contact.id,
          header: renderHeader,
          object: contact,
          objectFilter:
            contact.full_name_contact || formatPhoneNumber(contact.id_contact),
        })),
      })
    }

    const getUsersContacts = async () => {
      const response = await ContactService.getUserContactsByUser(
        user.user.id_user,
      )
      setContacts(response.data)
      inicializeData(response.data)
      setUsersContactsIsLoading(false)
    }

    getUsersContacts()

    // eslint-disable-next-line
  }, [updateContacts])

  if (usersContactsIsLoading) return <div>Carregando...</div>

  return (
    <>
      <Listview data={data} />
      <DialogContact
        id={user.user.id_user}
        open={openDialogContacts}
        onChangeOpen={setOpenDialogContacts}
        update={updateContacts}
        setUpdate={setUpdateContacts}
        contactsSelecteds={contacts}
      />
      <DialogDelete
        open={openDialogDelete}
        onChangeOpen={setOpenDialogDelete}
        user={userDelete}
        update={updateContacts}
        setUpdate={setUpdateContacts}
        toastDisabled={true}
      />
    </>
  )
}

export default ListViewUserContacts
