import { Container } from './styles'
import Chat from './components/Chat'
import MenuLateral from './components/MenuLateral'
import { useState, useEffect, useRef, useCallback } from 'react'
import {
  extractUsername,
  formatMessage,
  formatTimeSentWithDate,
} from '../../utils/format'
import { useSession } from '../../hook/session'
import { useImage } from '../../hook/image'
import FileService from '../../shared/services/File/FileService'
import ContactService from '../../shared/services/Contact/ContactService'
import { useTriggers } from '../../hook/triggers'
const Home = ({ socket }) => {
  const [contactFlag, setContactFlag] = useState(false)
  const [navigation, setNavigation] = useState('contacts')
  const [lateralContacts, setLateralContacts] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [listArchivedContacts, setListArchivedContacts] = useState([])
  const [messages, setMessages] = useState({})
  const { searchedMsg, enableNewMessage, typeSearch, isContactSearch } =
    useTriggers()

  // eslint-disable-next-line
  const currentMessages = messages[selectedContact] || []
  const messagesRef = useRef(messages)
  const messagesEndRef = useRef(null)
  const { setContactImage } = useImage()
  const { setSessionMedia, setSessionImageFilename, setSessionImageFiletype } =
    useSession()
  messagesRef.current = messages
  const LIMIT_MESSAGES = 25

  const handleSetSessionMedia = (media) => {
    socket.emit('getSessionInfo', async (res) => {
      setSessionImageFilename(res.image_filename_session)
      setSessionImageFiletype(res.image_filetype_session)
      const response = await FileService.getSrc(
        res.image_filename_session,
        res.image_filetype_session,
      )
      setSessionMedia(response.data.base64)
    })
  }

  const handleArchiveContact = async (id, name, isArchived) => {
    try {
      const data = {
        fullNameContact: name,
        isArchivedContact: isArchived,
      }
      await ContactService.updateContact(id, data)
      setSelectedContact(null)
      setLateralContacts((prevContacts) =>
        prevContacts.filter((contact) => contact.id !== id),
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeName = useCallback((err, response) => {
    if (err || !response || !response.id_contact || !response.full_name_contact)
      return
    const id = response.id_contact
    const newName = response.full_name_contact

    setLateralContacts((prev) =>
      prev.map((contact) =>
        contact.id === id ? { ...contact, name: newName } : contact,
      ),
    )
  }, [])

  const handleNewContact = useCallback((contact) => {
    const newContact = {
      id: contact.id_contact,
      image: contact.picture_contact,
      lastMessageTimestamp: new Date(),
      name: contact.full_name_contact,
      // newMessages: true,
      isViewedMessage: false,
      number: contact.full_name_contact,
      time: formatTimeSentWithDate(),
      unreadMessageCount: 1,
    }
    setContactImage(newContact.id, newContact.image)
    setLateralContacts((prev) => [newContact, ...prev])
    // eslint-disable-next-line
  }, [])

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  const handleNewMessage = useCallback(
    (message) => {
      if (enableNewMessage && typeSearch !== 'messages' && !isContactSearch) {
        const contactId =
          message.from_contact_message || message.to_contact_message
        const typeMessage = message.type_message
        const isMine = message.from_contact_message === null
        const newMessage = {
          message: formatMessage(message.content_message),
          isMine,
          idMessage: message.id_message,
          isNoteMessage: message.is_note_message,
          isViewdMessage: message.is_viewed_message,
          sendDateMessage: message.send_date_message,
          senderUserId: message.sender_user_id_message,
          typeMessage: message.type_message,
          fileName: message.file_name_message,
          fileType: message.file_type_message,
          userName: extractUsername(message.content_message),
          fileNameReply: message.file_name_reply_message,
          fileTypeReply: message.file_type_reply_message,
          idReplyMessage: message.id_reply_message,
          replyMessage: formatMessage(message.content_reply_message),
          sendDateReplyMessage: message.send_date_reply_message,
          isNoteReplyMessage: message.is_note_reply_message,
          isViewedReplyMessage: message.is_viewed_reply_message,
          senderUserIdReply: message.sender_user_id_reply_message,
          fromContactReplyMessage: message.from_contact_reply_message,
          toContactReplyMessage: message.to_contact_reply_message,
          typeReplyMessage: message.type_reply_message,
        }

        const updateMessages = (prev) => ({
          ...prev,
          [contactId]: [...(prev[contactId] || []), newMessage],
        })

        const updateContact = (contact) => {
          if (contact.id !== contactId) {
            return contact
          }
          return {
            ...contact,
            typeMessage,
            lastMessage:
              message.content_message || getDefaultLastMessage(typeMessage),
            lastMessageId: message.id_message,
            isViewedMessage: isMine,
            lastMessageTimestamp: new Date(message.send_date_message),
            unreadMessageCount: isMine
              ? contact.unreadMessageCount
              : contact.id === selectedContact
                ? 0
                : contact.unreadMessageCount + 1,
          }
        }

        const getDefaultLastMessage = (type) => {
          switch (type) {
            case 'image':
              return 'Novo arquivo'
            case 'ptt':
            case 'audio':
              return 'audio'
            case 'video':
              return 'Novo arquivo'
            case 'document':
              return 'documento'
            case 'sticker':
              return 'sticker'
            default:
              return ''
          }
        }

        setMessages(updateMessages)

        setLateralContacts((prev) =>
          prev
            .map(updateContact)
            .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp),
        )

        const isContact = lateralContacts?.find(
          (contact) => contact.id === contactId,
        )

        if (!isContact || isContact.isArchivedContact) {
          ContactService.getContact(contactId).then(async (res) => {
            const { data } = res
            if (data.is_archived_contact) {
              const contains = listArchivedContacts.includes(contactId)
              if (!contains) {
                setListArchivedContacts((prev) => {
                  if (!prev.includes(contactId)) {
                    return [...prev, contactId]
                  }
                  return prev
                })
              }
              return
            }
            const image = await getBase64Media(
              data.picture_filename_contact,
              data.picture_filetype_contact,
            )

            setContactImage(contactId, image)

            setLateralContacts((prev) => {
              const exists = prev.some((contact) => contact.id === contactId)

              if (exists) {
                return prev.map((contact) =>
                  contact.id === contactId
                    ? {
                        ...contact,
                        lastMessage: newMessage.message,
                        lastMessageTimestamp: new Date(),
                        lastMessageId: newMessage.idMessage,
                        unreadMessageCount: contact.unreadMessageCount + 1,
                        typeMessage: newMessage.typeMessage,
                      }
                    : contact,
                )
              }

              return [
                {
                  id: contactId,
                  image: image.split(';')[1],
                  lastMessageTimestamp: new Date(),
                  name: data.full_name_contact,
                  isViewedMessage: false,
                  number: contactId.split('@c.us')[0],
                  time: formatTimeSentWithDate(),
                  unreadMessageCount: 1,
                  lastMessage: newMessage.message,
                  lastMessageId: newMessage.idMessage,
                  typeMessage: newMessage.typeMessage,
                },
                ...prev,
              ]
            })
          })
        }
      }
    },
    // eslint-disable-next-line
    [
      selectedContact,
      enableNewMessage,
      lateralContacts,
      listArchivedContacts,
      setContactImage,
    ],
  )

  useEffect(() => {
    if (!contactFlag && !searchedMsg) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        setContactFlag(true)
      }
    }
  }, [currentMessages, contactFlag, searchedMsg])

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Conectado ao servidor')
      handleSetSessionMedia()
    })
    socket.on('newContact', handleNewContact)
    socket.on('newMessage', handleNewMessage)
    socket.on('changeName', handleChangeName)

    return () => {
      socket.off('connect')
      socket.off('changeName', handleChangeName)
      socket.off('newMessage', handleNewMessage)
      socket.off('newContact', handleNewContact)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, handleChangeName, handleNewMessage, handleNewContact])

  useEffect(() => {
    socket.emit('viewAllMessagens', selectedContact)
  }, [socket, selectedContact])

  return (
    <Container>
      <MenuLateral
        socket={socket}
        setMessages={setMessages}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
        navigation={navigation}
        setNavigation={setNavigation}
        LIMIT_MESSAGES={LIMIT_MESSAGES}
        setContactFlag={setContactFlag}
        setListArchivedContacts={setListArchivedContacts}
        listArchivedContacts={listArchivedContacts}
        handleArchiveContact={handleArchiveContact}
      />
      <Chat
        socket={socket}
        selectedContact={selectedContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
        currentMessages={currentMessages}
        messagesEndRef={messagesEndRef}
        navigation={navigation}
        setNavigation={setNavigation}
        LIMIT_MESSAGES={LIMIT_MESSAGES}
        setMessages={setMessages}
        handleArchiveContact={handleArchiveContact}
      />
    </Container>
  )
}

export default Home
