import React from 'react'
import Listview from '../../../../../../shared/components/Listview'
import ScheduleService from '../../../../../../shared/services/Schedule/ScheduleService'
import {
  BoxContainer,
  Container,
  Header,
  MagnifyingGlassPlusStyled,
  StyledPencil,
  StyledPlusCircle,
  StyledTrash,
  Title,
} from '../Gerenciamento/styles'
import DialogAddSchedules from './components/DialogAddSchedules'
import DialogSchedule from './components/DialogSchedule'
import {
  ContainerSchedule,
  ContainerScheduleActions,
  ContainerScheduleInfos,
  ContainerScheduleInfosInferior,
  ContainerScheduleInfosSuperior,
  Subtitle,
  TagStatus,
  TextContainer,
  TitleSchedule,
} from './styles'
import {
  formatDateWithYear,
  formatTimeSchedule,
  isDateGreaterThanToday,
} from '../../../../../../utils/format'
import { BackButton } from '../../styles'
import { CaretLeft } from 'phosphor-react'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'

const AgendamentoMensagens = ({ setNavigation }) => {
  const [scheduleIsLoading, setScheduleIsLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [selectedSchedule, setSelectedSchedule] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [openDialogSchedule, setOpenDialogSchedule] = React.useState(false)
  const [update, setUpdate] = React.useState(false)
  const theme = useTheme()

  const typeMessageOptions = {
    selecione: 'Selecione',
    chat: 'Chat',
    image: 'Imagem',
    ptt: 'Audio Gravado',
    audio: 'Áudio',
    video: 'Vídeo',
    document: 'Documento',
  }

  const handleCreateSchedule = () => {
    setOpenDialog(true)
    setSelectedSchedule(null)
  }

  const handleEditSchedule = (schedule) => {
    setOpenDialog(true)
    setSelectedSchedule(schedule)
  }

  const handleDeleteSchedule = async (schedule) => {
    const response = await ScheduleService.deleteSchedule(schedule.id_schedule)
    if (response.status === 204) {
      console.log('Agendamento deletado com sucesso')
      setUpdate(!update)
    } else {
      console.log('Erro ao deletar agendamento')
    }

    setUpdate(!update)
  }

  const handleSeeMoreSchedule = (schedule) => {
    setSelectedSchedule(schedule)
    setOpenDialogSchedule(true)
  }

  const renderHeader = (schedule) => {
    return (
      <ContainerSchedule>
        <ContainerScheduleInfos>
          <ContainerScheduleInfosSuperior>
            <TextContainer>
              <TitleSchedule>Mensagem:</TitleSchedule>
              <Subtitle>{schedule.content_message_schedule}</Subtitle>
            </TextContainer>
            <TagStatus
              className={
                isDateGreaterThanToday(schedule.timestamp_schedule) &&
                schedule.is_processed_schedule === true
                  ? 'cancelado'
                  : schedule.is_processed_schedule
                    ? 'enviado'
                    : 'agendado'
              }
            >
              {isDateGreaterThanToday(schedule.timestamp_schedule) &&
              schedule.is_processed_schedule === true
                ? 'Cancelado'
                : schedule.is_processed_schedule
                  ? 'Enviado'
                  : 'Agendado'}
            </TagStatus>
          </ContainerScheduleInfosSuperior>
          <ContainerScheduleInfosInferior>
            <TextContainer>
              <TitleSchedule>Tipo:</TitleSchedule>
              <Subtitle>
                {typeMessageOptions[schedule.type_message_schedule]}
              </Subtitle>
            </TextContainer>
            <TextContainer $mobileNone={true}>
              <TitleSchedule>Hora:</TitleSchedule>
              <Subtitle>
                {formatTimeSchedule(schedule.timestamp_schedule)}
              </Subtitle>
            </TextContainer>
            <TextContainer>
              <TitleSchedule>Data:</TitleSchedule>
              <Subtitle>
                {formatDateWithYear(schedule.timestamp_schedule)}
              </Subtitle>
            </TextContainer>
          </ContainerScheduleInfosInferior>
        </ContainerScheduleInfos>
        <ContainerScheduleActions>
          <MagnifyingGlassPlusStyled
            onClick={() => handleSeeMoreSchedule(schedule)}
          />
          <StyledPencil onClick={() => handleEditSchedule(schedule)} />
          <StyledTrash onClick={() => handleDeleteSchedule(schedule)} />
        </ContainerScheduleActions>
      </ContainerSchedule>
    )
  }

  React.useEffect(() => {
    const inicializeData = async (responseData) => {
      setData({
        title: 'Agendamento',
        accordion: true,
        create: (
          <StyledPlusCircle
            size={24}
            onClick={() => {
              handleCreateSchedule()
            }}
          />
        ),
        filter: true,
        setSelected: setSelectedSchedule,
        itens: responseData.map((schedule) => ({
          header: renderHeader,
          object: schedule,
          id: schedule.id_schedule,
          objectFilter:
            schedule.type_message_schedule === 'ptt'
              ? 'Audio Gravado'
              : schedule.type_message_schedule,
        })),
      })
    }

    const getSchedules = async () => {
      const response = await ScheduleService.getSchedules()
      console.log(response.data)
      await inicializeData(response.data)
      setScheduleIsLoading(false)
    }
    getSchedules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  if (scheduleIsLoading) {
    return (
      <Container>
        <Header>
          <BackButton onClick={() => setNavigation('configList')}>
            <CaretLeft
              size={32}
              weight="bold"
              style={{
                marginTop: '0.5rem',
              }}
            />
          </BackButton>
          <Title>Mensagens</Title>
        </Header>
        <BoxContainer className="loading">
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </BoxContainer>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.5rem',
            }}
          />
        </BackButton>
        <Title>Mensagens</Title>
      </Header>
      <BoxContainer>
        <Listview data={data} />
      </BoxContainer>
      <DialogSchedule
        open={openDialogSchedule}
        onChangeOpen={setOpenDialogSchedule}
        schedule={selectedSchedule}
        setSchedule={setData}
        typeMessageOptions={typeMessageOptions}
      />
      <DialogAddSchedules
        open={openDialog}
        onChangeOpen={setOpenDialog}
        update={update}
        setUpdate={setUpdate}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
        typeMessageOptions={typeMessageOptions}
      />
    </Container>
  )
}

export default AgendamentoMensagens
