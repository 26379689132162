import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
`

export const InputStyled = styled.input`
  width: 100%;
  padding: 0.5rem;
  border-radius: 12px !important;
  border: 2px solid ${({ theme }) => theme.colors.guto.BorderInput} !important;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  color: ${({ theme }) => theme.colors.Text};
  &::placeholder {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.colors.SubText};
  }

  &.contact {
    background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
    border: 1px solid ${({ theme }) => theme.colors.guto.Azul3};
  }

  &.dialog {
    background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
    border: 1px solid ${({ theme }) => theme.colors.guto.Azul3};
  }
`
