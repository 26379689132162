import styled from 'styled-components'

export const ContainerSchedule = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ContainerScheduleInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 80%;
  gap: 8px;
`

export const ContainerScheduleInfosSuperior = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
  }
`

export const ContainerScheduleInfosInferior = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
  }
`

export const ContainerScheduleActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const TitleSchedule = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const Subtitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const TagStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 60%;
  }

  &.enviado {
    background-color: #17c964;
    color: #000;
  }

  &.agendado {
    background-color: #10bbff;
    color: #000;
  }

  &.cancelado {
    background-color: #ef4444;
    color: #000;
  }
`
