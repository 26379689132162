import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 1.5rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  &.contact {
    padding: 0 2.5rem;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`

export const Title = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.Text};
  padding: 1rem;

  &.notFound {
    color: #f3f3f3;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
  }
`

export const TitleList = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.Text};
  padding: 1rem;

  &.contact {
    color: #f3f3f3;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const InputContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Separator = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SeparatorOnFundoAzul};
  align-self: center;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.contact {
    overflow: auto;
    max-height: 500px;
    padding: 0 3.5rem;
    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
`
