import React, { createContext, useState, useContext } from 'react'

const TriggersContext = createContext()

export const TriggersProvider = ({ children }) => {
  const [isOpenTags, setIsOpenTags] = useState(false)
  const [isOpenUsers, setIsOpenUsers] = useState(false)
  const [isOpenLocal, setIsOpenLocal] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [searchedMsg, setSearchedMsg] = useState({})
  const [typeSearch, setTypeSearch] = useState('contacts')
  const [enableNewMessage, setEnableNewMessage] = useState(true)
  const [isOpenMidia, setIsOpenMidia] = useState(false)
  const [isContactSearch, setIsContactSearch] = useState(false)

  return (
    <TriggersContext.Provider
      value={{
        isOpenTags,
        setIsOpenTags,
        isOpenUsers,
        setIsOpenUsers,
        isOpenLocal,
        setIsOpenLocal,
        isOpenMidia,
        setIsOpenMidia,
        isOpenEdit,
        setIsOpenEdit,
        searchedMsg,
        setSearchedMsg,
        typeSearch,
        setTypeSearch,
        enableNewMessage,
        setEnableNewMessage,
        isContactSearch,
        setIsContactSearch,
      }}
    >
      {children}
    </TriggersContext.Provider>
  )
}

export const useTriggers = () => {
  const context = useContext(TriggersContext)
  if (!context) {
    throw new Error('useTriggers must be used within a TriggersProvider')
  }
  return context
}
