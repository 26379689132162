import Botflux from './api/Botflux'

class BotService {
  async getBotfluxs() {
    try {
      const response = await Botflux.getBotfluxs()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createBotflux(data) {
    try {
      const response = await Botflux.createBotflux(data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteBotflux(id) {
    try {
      const response = await Botflux.deleteBotflux(id)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateBotflux(idFlux, idContact) {
    try {
      const response = await Botflux.updateBotflux(idFlux, idContact)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async putBotflux(idBotFlux, data) {
    try {
      const response = await Botflux.putBotflux(idBotFlux, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async refreshBotflux() {
    try {
      const response = await Botflux.refreshBotflux()
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async defineInitial(idBotFlux) {
    try {
      const response = await Botflux.defineInitial(idBotFlux)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async disableBotflux(idContact) {
    try {
      const response = await Botflux.disableBotflux(idContact)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export default new BotService()
