import React, { useEffect, useState } from 'react'
import {
  Container,
  ContactImage,
  DivColumn,
  DivRow,
  NameContact,
  IconsEnd,
  CaretContainer,
  CaretLeftStyled,
  UserListStyled,
  TagStyled,
  MoreVertIconStyled,
  ApertureStyled,
  RobotStyled,
} from './styles'
import imageContato from '../../../../../../assets/images/imageContato'
import { extractImage } from '../../../../../../utils/format'
import DialogEdit from './components/DialogEdit'
import DialogUsers from './components/DialogUsers'
import DialogTags from './components/DialogTags'
import DialogMidia from './components/DialogMidia'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import FileService from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'
import { useTriggers } from '../../../../../../hook/triggers'
import { useSession } from '../../../../../../hook/session'
import DialogTypebot from './components/DialogTypebot'

const ContactSuperior = ({
  socket,
  dataContact,
  setNavigation,
  lateralContacts,
  setLateralContacts,
  handleArchiveContact,
}) => {
  const {
    isOpenLocal,
    setIsOpenLocal,
    isOpenTags,
    setIsOpenTags,
    isOpenUsers,
    setIsOpenUsers,
    isOpenEdit,
    setIsOpenEdit,
    isOpenMidia,
    setIsOpenMidia,
  } = useTriggers()
  const [editedNames, setEditedNames] = useState({
    [dataContact.id]: dataContact.name,
  })
  const [error, setError] = useState('')
  const { contactImage, setContactImage } = useImage()
  const [nameContact, setNameContact] = useState(dataContact.name)
  const { verifyIsRoot } = useSession()
  const [contactDateEnter, setContactDateEnter] = useState({})
  const [loading, setLoading] = useState(true)
  const [expandedDescription, setExpandedDescription] = useState(false)
  const [isLongDescription, setIsLongDescription] = useState(false)
  const [isOpenTypebot, setIsOpenTypebot] = useState(false)

  useEffect(() => {
    const getEnterDate = async () => {
      const response = await ContactService.getEnterDate(dataContact.id)
      setContactDateEnter(response.data)
      setLoading(false)
    }
    getEnterDate()
  }, [dataContact.id])

  useEffect(() => {
    if (!isOpenLocal) {
      setNameContact('')
    }
  }, [isOpenLocal])

  useEffect(() => {
    if (dataContact && dataContact.name) {
      setEditedNames((prevNames) => ({
        ...prevNames,
        [dataContact.id]: dataContact.name,
      }))
    }
  }, [dataContact])

  useEffect(() => {
    if (!isOpenLocal) {
      setIsOpenEdit(false)
      setError('')
    }
  }, [isOpenLocal, setIsOpenEdit])

  useEffect(() => {
    if (dataContact.summaryContact && dataContact.summaryContact.length > 150) {
      setIsLongDescription(true)
    } else {
      setIsLongDescription(false)
    }
  }, [dataContact.summaryContact])

  const handleOpenModal = () => {
    setIsOpenLocal(true)
  }

  const handleSyncImage = async () => {
    try {
      const response = await ContactService.syncImageUser(dataContact.id)
      const filename = response.data.picture_filename_contact
      const filetype = response.data.picture_filetype_contact
      const resp = await FileService.getSrc(filename, filetype)
      if (resp.data.base64.startsWith('undefined')) {
        resp.data.base64 = resp.data.base64.replace(
          'undefined',
          'data:image/png;base64',
        )
      }
      setContactImage(dataContact.id, resp.data.base64)
    } catch (error) {
      setContactImage(dataContact.id, imageContato)
      console.log(error)
    }
  }

  const editContact = () => {
    setIsOpenEdit(!isOpenEdit)
    setError('')
  }

  const handleSave = async () => {
    try {
      const newName = nameContact
      if (!newName.trim()) {
        setError('O nome não pode estar vazio.')
        return
      }
      setError('')
      setIsOpenEdit(false)
      const result = await ContactService.updateContact(dataContact.id, {
        fullNameContact: newName,
      })
      if (result.status === 200) {
        setEditedNames((prevNames) => ({
          ...prevNames,
          [dataContact.id]: newName,
        }))
        setLateralContacts((prevContacts) =>
          prevContacts.map((contact) =>
            contact.id === dataContact.id
              ? { ...contact, name: newName }
              : contact,
          ),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleDescription = () => {
    setExpandedDescription(!expandedDescription)
  }

  return (
    <Container>
      <DivRow>
        <CaretContainer onClick={() => setNavigation('contacts')}>
          <CaretLeftStyled weight="bold" />
        </CaretContainer>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ContactImage
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ContactImage src={imageContato} alt="Imagem do contato" />
        )}
        <DivColumn>
          <NameContact>
            {editedNames[dataContact.id] ?? dataContact.name}
          </NameContact>
        </DivColumn>
      </DivRow>
      <IconsEnd>
        <RobotStyled onClick={() => setIsOpenTypebot(true)} />
        <TagStyled onClick={() => setIsOpenTags(true)} />
        {verifyIsRoot() && (
          <UserListStyled onClick={() => setIsOpenUsers(true)} />
        )}
        <ApertureStyled onClick={() => setIsOpenMidia(true)} />
        <MoreVertIconStyled onClick={handleOpenModal} />
      </IconsEnd>
      <DialogTags
        id={dataContact.id}
        open={isOpenTags}
        onChangeOpen={setIsOpenTags}
        contact={dataContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
      />
      <DialogUsers
        id={dataContact.id}
        open={isOpenUsers}
        onChangeOpen={setIsOpenUsers}
        contact={dataContact}
      />

      <DialogEdit
        isOpen={isOpenLocal}
        onChangeOpen={setIsOpenLocal}
        contactImage={contactImage}
        dataContact={dataContact}
        contactDateEnter={contactDateEnter}
        loading={loading}
        verifyIsRoot={verifyIsRoot}
        handleSyncImage={handleSyncImage}
        isOpenEdit={isOpenEdit}
        nameContact={nameContact}
        setNameContact={setNameContact}
        error={error}
        handleSave={handleSave}
        editContact={editContact}
        editedNames={editedNames}
        handleArchiveContact={handleArchiveContact}
        expandedDescription={expandedDescription}
        isLongDescription={isLongDescription}
        toggleDescription={toggleDescription}
        setEditedNames={setEditedNames}
        setLateralContacts={setLateralContacts}
      />

      <DialogMidia
        open={isOpenMidia}
        onChangeOpen={setIsOpenMidia}
        socket={socket}
        dataContact={dataContact}
      />

      <DialogTypebot
        open={isOpenTypebot}
        onChangeOpen={setIsOpenTypebot}
        contact={dataContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
        id={dataContact.id}
      />
    </Container>
  )
}

export default ContactSuperior
