import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
  align-self: flex-start;
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;
`

export const StyledInput = styled.input`
  flex: 1;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.Text};
  padding: 8px;
  font-size: 16px;

  &:focus {
    outline: none;
  }
`

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const StyledPhrase = styled.p`
  color: ${({ theme }) => theme.colors.Text};
`
export const FileNameDisplay = styled.span`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 0.875rem;
  margin-left: 8px;
`
