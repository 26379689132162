import { useEffect, useState } from 'react'
import {
  Container,
  ContainerUsers,
  Email,
  ListUsers,
  NameEmailSection,
  Separator,
  Title,
} from './styles'
import {
  Contact,
  ContactNotFound,
  ContactText,
  DivRowContact,
  DivRowSupContacts,
  StyledPlusCircle,
  StyledTrash,
  UserActions,
} from '../../../../../../../Settings/components/MainScreen/components/Gerenciamento/styles'
import Input from '../../../../../../../../shared/components/Input'
import UserService from '../../../../../../../../shared/services/User/UserService'
import DialogContacts from './components/DialogContacts'
import { Header } from '../DialogTags/components/DialogAddTags/styles'
import LoadingSpinner from '../../../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import DialogDelete from './components/DialogDelete'
import Dialog from '../../../../../../../../shared/components/Dialog'

const DialogUsers = ({
  open,
  onChangeOpen,
  showIconClose = true,
  id,
  contact,
}) => {
  const theme = useTheme()
  const [users, setUsers] = useState([])
  const [searchUser, setSearchUser] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userDeleted, setUserDeleted] = useState({})
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const [dialogWidth, setDialogWidth] = useState(
    window.innerWidth < 800
      ? '70vw'
      : window.innerWidth < 1000
        ? '50vw'
        : '45vw',
  )

  useEffect(() => {
    const handleResize = () => {
      setDialogWidth(window.innerWidth < 800 ? '70vw' : '45vw')
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const filteredUsers = users.filter((user) => {
    const email = user?.email_user?.toLowerCase()
    const name = user?.full_name_user?.toLowerCase()
    return (
      email.includes(searchUser.toLowerCase()) ||
      name.includes(searchUser.toLowerCase())
    )
  })

  useEffect(() => {
    if (!open) {
      setLoading(true)
      setUsers([])
    }
  }, [open])

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUserByIdContact(id)
      if (response.data.users) {
        setUsers(response.data.users)
      } else {
        setUsers([])
      }
      setLoading(false)
    }
    getUsers()
  }, [id, update, contact, open])

  const handleDeleteUser = async (user) => {
    setUserDeleted(user)
    setOpenDialogDelete(true)
  }

  const renderUsers = () => {
    if (loading) {
      return (
        <ContactNotFound>
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </ContactNotFound>
      )
    }

    if (filteredUsers.length > 0) {
      return filteredUsers.map((user) => {
        return (
          <>
            <Contact key={user.id_user}>
              <DivRowContact>
                <NameEmailSection>
                  <ContactText>{user.full_name_user}</ContactText>
                  <Email>{user.email_user}</Email>
                </NameEmailSection>
                <UserActions>
                  <StyledTrash
                    size={24}
                    onClick={() => handleDeleteUser(user)}
                  />
                </UserActions>
              </DivRowContact>
            </Contact>
            <Separator />
          </>
        )
      })
    } else {
      return <ContactNotFound>Nenhum usuário encontrado</ContactNotFound>
    }
  }

  return (
    <Dialog
      open={open}
      onChangeOpen={onChangeOpen}
      showIconClose={showIconClose}
      width={dialogWidth}
    >
      <Container>
        <Header>
          <Title>Gerenciamento de contato</Title>
        </Header>
        <ListUsers>
          <DivRowSupContacts>
            <Input
              placeholder="Pesquisar usuarios"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              className={'dialog'}
            />
            <StyledPlusCircle
              style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
              size={24}
              onClick={() => setOpenDialog(true)}
            />
            <DialogContacts
              open={openDialog}
              onChangeOpen={setOpenDialog}
              id={id}
              update={update}
              setUpdate={setUpdate}
              usersSelecteds={users}
              setUsersSelecteds={setUsers}
            />
          </DivRowSupContacts>
          <ContainerUsers>{renderUsers()}</ContainerUsers>
        </ListUsers>
      </Container>
      <DialogDelete
        open={openDialogDelete}
        onChangeOpen={setOpenDialogDelete}
        user={userDeleted}
        update={update}
        setUpdate={setUpdate}
      />
    </Dialog>
  )
}

export default DialogUsers
