import { api } from '../../api/axios'

export default new (class AiApi {
  async getAvaliacao(id) {
    try {
      const response = await api.get(`/avaliar/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
