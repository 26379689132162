import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const NameEmailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Email = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 10px;
`

export const ListTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SeparatorOnFundoAzul};
  align-self: center;
`

export const ContainerTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 0 0 0 0.2rem;
`

export const Title = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.293px; /* 105.733% */
  color: ${({ theme }) => theme.colors.Text};
`
