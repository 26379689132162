import styled from 'styled-components'
import * as DialogRadix from '@radix-ui/react-dialog'
import { XCircle } from 'phosphor-react'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Subtitle = styled(DialogRadix.Description)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const ListContacts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 45vh;
  gap: 8px;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
  border-radius: 8px;
  overflow: auto;
  margin: 1rem 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const DivRowSupContacts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.guto.Azul1 : theme.colors.guto.Azul2};
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.guto.Azul1 : `${theme.colors.guto.Azul3}70`};
  }
`

export const ContactText = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  padding: 0.1rem;
`

export const DivRowContact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 1rem;
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const NoContacts = styled.h2`
  font-size: 1rem;
  color: red;
  padding: 0.1rem;
  align-self: center;
`

export const XCircleStyled = styled(XCircle)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`
