import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const NameEmailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Email = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Title = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.293px;
  color: ${({ theme }) => theme.colors.Text};
  @media (max-width: 500px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
  }
`

export const ListUsers = styled.div`
  display: flex;
  flex-direction: column;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SeparatorOnFundoAzul};
  align-self: center;
`

export const ContainerUsers = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0.2rem;
`
