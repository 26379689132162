import { useEffect, useState } from 'react'
import {
  ActionsBot,
  ActionsBotText,
  BoxContainer,
  ContainerActions,
  Header,
  Title,
  Typebot,
  TypebotDescription,
  TypebotInitials,
} from './styles'
import { BackButton } from '../../styles'
import { Container } from 'react-bootstrap'
import { CaretLeft } from 'phosphor-react'
import {
  StyledDots,
  StyledFirst,
  StyledPencil,
  StyledRefresh,
} from '../Gerenciamento/styles'
import BotService from '../../../../../../shared/services/Botflux/BotService'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import Listview from '../../../../../../shared/components/Listview'
import DialogBotFlux from './components/DialogEditBot'
import { Bounce, toast } from 'react-toastify'
import * as Popover from '@radix-ui/react-popover'
import {
  PopoverArrow,
  PopoverContent,
} from '../../../../../Home/components/MenuLateral/components/SearchMenu/styles'

const Bots = ({ setNavigation }) => {
  const [data, setData] = useState([])
  const [botsIsLoading, setBotsIsLoading] = useState(true)
  const [update, setUpdate] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedBot, setSelectedBot] = useState(null)
  const theme = useTheme()

  const handleEditTypebot = async (typebot) => {
    setOpenDialog(true)
    setSelectedBot(typebot)
  }

  const handleDefineInitial = async (typebotId) => {
    const res = await BotService.defineInitial(typebotId)

    if (res.status === 200) {
      toast.success('Bot definido como inicial com sucesso!', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setUpdate(!update)
    } else {
      toast.error('Erro ao definir bot como inicial', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const renderHeader = (typebot) => {
    return (
      <Typebot key={typebot.id_bot_flux}>
        <TypebotInitials>
          <p>
            <strong>ID:</strong> {typebot.id_bot_flux}
          </p>
          <p>
            <strong>Nome: </strong>
            {typebot.name_bot_flux}
          </p>
        </TypebotInitials>
        <TypebotDescription>
          <p>
            <strong>Texto de ajuda:</strong> {typebot.description_bot_flux}
          </p>
        </TypebotDescription>
        <ContainerActions>
          <StyledPencil size={24} onClick={() => handleEditTypebot(typebot)} />
          {typebot.is_first_bot_flux ? (
            <StyledFirst size={24} />
          ) : (
            <Popover.Root>
              <Popover.Trigger asChild>
                <StyledDots size={24} />
              </Popover.Trigger>
              <Popover.Portal>
                <PopoverContent className="PopoverContent" sideOffset={5}>
                  <ActionsBot>
                    <ActionsBotText
                      onClick={() => handleDefineInitial(typebot.id_bot_flux)}
                    >
                      Definir como inicial
                    </ActionsBotText>
                  </ActionsBot>
                  <PopoverArrow className="PopoverArrow" />
                </PopoverContent>
              </Popover.Portal>
            </Popover.Root>
          )}
        </ContainerActions>
      </Typebot>
    )
  }

  const handleRefresh = async () => {
    const response = await BotService.refreshBotflux()
    console.log('response', response)
    if (response.status === 200) {
      toast.success('Bots sincronizados com sucesso!', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setUpdate(!update)
    } else {
      toast.error('Erro ao sincronizar bots', {
        transition: Bounce,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(() => {
    const inicializeData = async (responseData) => {
      console.log('responseData', responseData)
      setData({
        title: 'Gerenciamento',
        accordion: true,
        filter: true,
        create: <StyledRefresh size={24} onClick={() => handleRefresh()} />,
        itens: responseData.map((bot) => ({
          id: bot.id_bot_flux,
          object: bot,
          objectFilter: bot.name_bot_flux,
          header: renderHeader,
        })),
      })
    }

    const getBots = async () => {
      const response = await BotService.getBotfluxs()
      await inicializeData(response.data)
      setBotsIsLoading(false)
    }
    getBots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  if (botsIsLoading) {
    return (
      <Container>
        <Header>
          <BackButton onClick={() => setNavigation('configList')}>
            <CaretLeft
              size={32}
              weight="bold"
              style={{
                marginTop: '0.5rem',
              }}
            />
          </BackButton>
          <Title>Bots</Title>
        </Header>
        <BoxContainer className="loading">
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </BoxContainer>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.5rem',
            }}
          />
        </BackButton>
        <Title>Bots</Title>
      </Header>
      <BoxContainer>
        <Listview data={data} />
      </BoxContainer>
      <DialogBotFlux
        open={openDialog}
        onChangeOpen={setOpenDialog}
        setUpdate={setUpdate}
        typebot={selectedBot}
      />
    </Container>
  )
}

export default Bots
