import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Container,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
  ModalImage,
  ModalImageContainer,
  // FirstContainer,
  // ContainerName,
} from './styles.js'
import Input from '../../../../../../../../shared/components/InputForm/index.jsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import UserService from '../../../../../../../../shared/services/User/UserService.js'
import FileService from '../../../../../../../../shared/services/File/FileService.js'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../shared/components/Dialog/index.jsx'

const DialogUserEdit = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  user,
  socket,
  seuPerfil,
}) => {
  const userScheme = seuPerfil
    ? z.object({
        fullNameUser: z.string().min(1, 'Nome é obrigatório'),
        userImage: z.any(),
      })
    : z.object({
        fullNameUser: z.string().min(1, 'Nome é obrigatório'),
        userImage: z.any(),
      })

  const [file, setFile] = useState(null)
  const [imgFlag, setImgFlag] = useState(false)
  const [imagePreview, setImagePreview] = useState(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setImagePreview(previewUrl)
    } else {
      setImagePreview(null)
    }

    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(userScheme),
  })

  const getBase64 = async (filename, filetype) => {
    if (!filename || !filetype) {
      return
    }
    try {
      const response = await FileService.getSrc(filename, filetype)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const initializeUser = async () => {
    const img = await getBase64(
      user.picture_filename_user,
      user.picture_filetype_user,
    )
    setValue('fullNameUser', user.full_name_user)
    setValue('emailUser', user.email_user)
    if (!seuPerfil) setValue('passwordUser', '')
    setValue('isRootUser', user.is_root_user)
    setValue('userImage', img)
    setImagePreview(img)
  }

  useEffect(() => {
    if (user) {
      initializeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setValue])

  useEffect(() => {
    if (!open) {
      reset()
      setFile(null)
      setImagePreview(null)
    }
  }, [open, reset])

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  const onSubmit = async (data) => {
    const image = data.userImage[0]
    delete data.userImage
    const res = await UserService.updateUser(user.id_user, data)
    if (res.status === 200) {
      if (image && imgFlag) {
        // logica da imagem
        let base64 = await fileToBase64(image)
        base64 = base64.split(',')[1]
        await UserService.updateImage(user.id_user, base64)
      }

      toast.success('Usuário editado com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      setUpdate(!update)
      handleCloseDialog()
    }
  }

  if (user) {
    return (
      <Dialog
        open={open}
        onChangeOpen={onChangeOpen}
        showIconClose={showIconClose}
      >
        <Container>
          <Header>
            <Title>Editar usuário:</Title>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Label>Foto de usuário:</Label>
            <Input
              type="file"
              ref={fileInputRef}
              {...register('userImage')}
              onChange={(event) => {
                const selectedFile = event.target.files[0]
                if (selectedFile) {
                  setImgFlag(true)
                  setFile(selectedFile)
                }
              }}
            />
            <ErrorSpan $span={!!errors.userImage?.message}>
              {errors.userImage?.message}
            </ErrorSpan>
            {imagePreview && (
              <ModalImageContainer>
                <ModalImage
                  src={imagePreview}
                  alt="Preview da imagem selecionada"
                />
              </ModalImageContainer>
            )}
            <Label>Nome:</Label>
            <Input
              type="text"
              placeholder="Nome"
              {...register('fullNameUser')}
            />
            <ErrorSpan $span={!!errors.fullNameUser?.message}>
              {errors.fullNameUser?.message || 'a'}
            </ErrorSpan>
            <DivButton>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <ButtonCadastro type="submit">Editar</ButtonCadastro>
            </DivButton>
          </Form>
        </Container>
      </Dialog>
    )
  }
}

export default DialogUserEdit
