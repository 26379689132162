import axios from 'axios'
import LocalStorage from '../../../utils/LocalStorage'
import { jwtDecode } from 'jwt-decode'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use(
  (response) => {
    if (response.config.url?.includes('user/login')) {
      LocalStorage.setValue('token', response.data.token)
    }
    return response
  },
  (error) => {
    if (error.response?.status === 409) {
      LocalStorage.clearAllValues()
      window.location.href = '/'
    }
    return Promise.reject(error)
  },
)

api.interceptors.request.use(
  async (config) => {
    if (
      config.url?.includes('user/login') ||
      config.url?.includes('user/forget-password')
    ) {
      return config
    }

    const token = LocalStorage.getValue('token')

    if (!token) {
      window.location.href = ''
    }

    const currentTime = Date.now() / 1000
    const decodedToken = jwtDecode(token)

    if (!!decodedToken.exp && decodedToken.exp < currentTime) {
      LocalStorage.clearAllValues()
      window.location.href = ''
    }

    config.headers.Authorization = 'Bearer ' + token

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
