import React, { useEffect, useState } from 'react'
import * as S from './styles'
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from './components/DropdownComponents/index'
import IconSearch from '../../../assets/svg/icon_search.jsx'
import { formatTextTrigger } from '../../../utils/format.js'

const Dropdown = ({
  name,
  items,
  handleClick,
  selectedValue,
  $label,
  $noFound,
  $LeftIcon,
  $width,
  $onBlur,
  setSelectedValue,
}) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [openState, setOpenState] = useState('closed')

  const toggleDropdown = (value) => {
    setOpenState((prev) => (prev === 'closed' ? value : 'closed'))
  }

  const onClickDropdown = (item) => {
    if (selectedItems.some((i) => i.value === item.value)) {
      // Deselecionar item
      const updatedSelection = selectedItems.filter(
        (i) => i.value !== item.value,
      )
      setSelectedItems(updatedSelection)
      setSelectedValue(updatedSelection.map((i) => i.value))
      $onBlur(name, updatedSelection)
    } else {
      // Selecionar item
      const updatedSelection = [...selectedItems, item]
      setSelectedItems(updatedSelection)
      setSelectedValue(updatedSelection.map((i) => i.value))
      $onBlur(name, updatedSelection)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      const valueArray = Array.isArray(selectedValue)
        ? selectedValue
        : [selectedValue]
      const selectedFromItems = items.filter((i) =>
        valueArray.includes(i.value),
      )
      setSelectedItems(selectedFromItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, items])

  return (
    <AccordionRoot $value={openState} $onChange={toggleDropdown}>
      <AccordionItem value="buttonExit" $width={$width}>
        <AccordionHeader>
          <AccordionTrigger>
            {$LeftIcon}
            <p>
              {selectedItems.length > 0
                ? // ? selectedItems.map((i) => i.label).join(', ')
                  formatTextTrigger(selectedItems)
                : $label}
            </p>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent>
          <S.SectionSearch>
            <S.Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              type="text"
              placeholder="Pesquisar"
            />
            <IconSearch />
          </S.SectionSearch>
          <S.ScrollArea $height="7.375rem">
            {(function (filterItems) {
              if (filterItems.length === 0)
                return <div className="noFoundCategory">{$noFound}</div>
              return filterItems.map((item) => (
                <S.Item key={item.value} onClick={() => onClickDropdown(item)}>
                  <S.ItemParagraph
                    className={
                      selectedItems.some((i) => i.value === item.value)
                        ? 'selected'
                        : ''
                    }
                  >
                    {item.label}
                    {selectedItems.some((i) => i.value === item.value) && (
                      <S.XCircleStyled size={16} />
                    )}
                  </S.ItemParagraph>
                </S.Item>
              ))
            })(
              items.filter((item) =>
                item?.label?.toLowerCase().includes(searchValue.toLowerCase()),
              ),
            )}
          </S.ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  )
}

export default Dropdown
