import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem 2rem;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const Title = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.293px;
  color: ${({ theme }) => theme.colors.Text};
`

export const Label = styled.h2`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`

export const ButtonCadastro = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  color: ${({ theme }) => theme.colors.Text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const ErrorSpan = styled.span`
  color: ${(props) =>
    props.$span ? props.theme.colors.guto.Vermelho : 'transparent'};
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: -0.8rem;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.8rem;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.Separator};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
    border-color: ${({ theme }) => theme.colors.Separator};
  }

  &:checked::after {
    content: '\\2714';
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
  }
`

export const ModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalImage = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  max-width: 50%;
  align-self: center;
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`

export const ContainerName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 55%;
`
