import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components'
import 'react-phone-input-2/lib/style.css'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;

  .custom-phone-input {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.Separator};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
    padding: 0.5rem;
  }

  .custom-phone-input .form-control {
    width: 100%;
    background: transparent !important;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.Text};
    font-size: 1rem;
    padding-left: 40px;
  }

  /* Força a bandeira a manter a cor correta */
  .custom-phone-input .flag-dropdown {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
    border: none !important;
  }

  /* Mantém a bandeira com a cor correta quando o dropdown está aberto */
  .custom-phone-input .flag-dropdown.open {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
  }

  /* Remove qualquer borda extra ao abrir */
  .custom-phone-input .flag-dropdown.open .selected-flag {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
  }

  /* Remove o hover branco da bandeira */
  .custom-phone-input .selected-flag:hover {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
  }

  /* Personaliza o dropdown de países */
  .custom-phone-input .country-list {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
    border: 1px solid ${({ theme }) => theme.colors.Separator};
  }

  .custom-phone-input .country-list .search {
    background: ${({ theme }) => theme.colors.guto.BaseAzul1} !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Separator};
    color: ${({ theme }) => theme.colors.Text} !important;
  }

  .custom-phone-input .country-list .country {
    color: ${({ theme }) => theme.colors.Text};
  }

  .custom-phone-input .country-list .country:hover {
    background: ${({ theme }) => theme.colors.Separator} !important;
  }

  .custom-phone-input .country-list .highlight {
    background: ${({ theme }) => theme.colors.Separator} !important;
    color: ${({ theme }) => theme.colors.Text} !important;
  }
`

const CustomPhoneInput = ({ onChange }) => {
  const [phone, setPhone] = useState('')

  const handlePhoneChange = (value, country) => {
    setPhone(value)
    if (onChange) {
      onChange(value, country)
    }
  }

  return (
    <Container>
      <PhoneInput
        country={'br'}
        value={phone}
        onChange={handlePhoneChange}
        enableSearch={true}
        containerClass="custom-phone-input"
      />
    </Container>
  )
}

export default CustomPhoneInput
