import { RocketLaunch } from 'phosphor-react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  padding: 1.8rem 1rem;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  height: 10vh;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 90vh;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};

  &.loading {
    justify-content: center;
    align-items: center;
  }
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Typebot = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.Text};
  width: 95%;
  align-self: center;
`

export const TypebotInitials = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`

export const TypebotDescription = styled.p`
  font-size: 0.8rem;
  width: 33%;
  display: flex;
  text-align: justify;
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

export const RocketLaunchStyled = styled(RocketLaunch)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
`

export const ActionsBot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
`

export const ActionsBotText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
  text-align: center;
  margin-top: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.guto.Azul3};
  }
`
