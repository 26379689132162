import * as Accordion from '@radix-ui/react-accordion'
import {
  MyRoot,
  MyAccordionItem,
  MyHeader,
  MyTrigger,
  MyContent,
} from './styles.js'
import React from 'react'

export const AccordionRoot = ({ children, $value, $onChange }) => (
  <Accordion.Root
    asChild
    type="single"
    collapsible
    value={$value}
    onValueChange={$onChange}
  >
    <MyRoot>{children}</MyRoot>
  </Accordion.Root>
)

export const AccordionItem = ({ children, value, $width }) => (
  <Accordion.Item asChild value={value}>
    <MyAccordionItem $width={$width}>{children}</MyAccordionItem>
  </Accordion.Item>
)

export const AccordionHeader = ({ children }) => (
  <Accordion.Header asChild>
    <MyHeader>{children}</MyHeader>
  </Accordion.Header>
)

export const AccordionTrigger = ({ children, className }) => (
  <Accordion.Trigger asChild>
    <MyTrigger className={className}>{children}</MyTrigger>
  </Accordion.Trigger>
)

export const AccordionContent = ({ children }) => (
  <Accordion.Content asChild>
    <MyContent>{children}</MyContent>
  </Accordion.Content>
)
