import { api } from '../../api/axios'

export default new (class ContactApi {
  async getEnterDate(id) {
    try {
      const response = await api.get(`/contact/enter-date/${id}`)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  async getContact(id) {
    try {
      const response = await api.get(`/contact/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async storeContact(data) {
    try {
      const response = await api.post('/contact', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAllContacts() {
    try {
      const response = await api.get('/contact')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContactsByUser(id) {
    try {
      const response = await api.get(`/users-contacts/user/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContacts() {
    try {
      const response = await api.get('/users-contacts')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createUserContact(data) {
    try {
      const response = await api.post('/users-contacts', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async syncImageUSer(id) {
    try {
      const response = await api.put(`/contact/sync-image/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteUserContact(id, pass) {
    try {
      const response = await api.delete(`/users-contacts/${id}`, {
        data: { password_user: pass },
      })

      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateContact(id, data) {
    try {
      const response = await api.put(`/contact/${id}`, data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAllArchivedContacts() {
    try {
      const response = await api.get('/contact/archived-with-unread-messages')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
