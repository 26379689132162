import StickerApi from './api/StickerApi'

class StickerService {
  async FavoriteSticker(data) {
    try {
      return StickerApi.Favorite(data)
    } catch (error) {
      return error
    }
  }

  async FindAllStickers() {
    try {
      return StickerApi.FindAll()
    } catch (error) {
      return error
    }
  }

  async DeleteSticker(id) {
    try {
      return StickerApi.Delete(id)
    } catch (error) {
      return error
    }
  }
}

export default new StickerService()
