import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  Header,
  Title,
  Subtitle,
  ListContacts,
  DivRowSupContacts,
  Contact,
  ContactText,
  DivRowContact,
  DivButton,
  Button,
  ButtonCancel,
  ButtonCadastro,
  NoContacts,
} from './styles.js'
import ContactService from '../../../../../../../../shared/services/Contact/ContactService.js'
import { XCircle } from 'phosphor-react'
import DialogCadastro from './components/DialogCadastro/index.jsx'
// import { useToast } from '../../../../../../../../hook/toast.jsx'
import Input from '../../../../../../../../shared/components/Input/index.jsx'
import { Bounce, toast } from 'react-toastify'
import Dialog from '../../../../../../../../shared/components/Dialog/index.jsx'

const DialogContacts = ({
  id,
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
  contactsSelecteds,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [searchContact, setSearchContact] = useState('')
  const [contacts, setContacts] = useState([])
  const [selectedContacts, setSelectedContacts] = useState([])
  const [updateContacts, setUpdateContacts] = useState(false)
  // const { addToast } = useToast()

  const handleSelectContact = (contact) => {
    const index = selectedContacts.findIndex(
      (selectedContact) => selectedContact.id_contact === contact.id_contact,
    )
    if (index === -1) {
      setSelectedContacts([...selectedContacts, contact])
    } else {
      setSelectedContacts(
        selectedContacts.filter(
          (selectedContact) =>
            selectedContact.id_contact !== contact.id_contact,
        ),
      )
    }
  }

  const handleCloseDialog = () => {
    onChangeOpen(false)
    setSelectedContacts([])
  }

  const filteredContactsByNameOrNumber = () => {
    if (searchContact) {
      return contacts.filter((contact) => {
        const name = contact.full_name_contact.toLowerCase()
        const number = contact.id_contact.substring(0, 12).toLowerCase()
        const search = searchContact.toLowerCase()
        if (contacts.length === 0) return false
        return name.includes(search) || number.includes(search)
      })
    } else {
      return contacts
    }
  }

  useEffect(() => {
    const getContacts = async () => {
      const response = await ContactService.getAllContacts()
      setContacts(
        response.data.filter(
          (contact) =>
            contactsSelecteds.findIndex(
              (contactSelected) =>
                contactSelected.id_contact === contact.id_contact,
            ) === -1,
        ),
      )
    }
    getContacts()
  }, [contactsSelecteds, updateContacts])

  const verifySelectedContact = useCallback(
    (contact) => {
      return (
        selectedContacts.findIndex(
          (selectedContact) =>
            selectedContact.id_contact === contact.id_contact,
        ) !== -1
      )
    },
    [selectedContacts],
  )

  const handleSave = async () => {
    const data = {
      IDsUserUsersContacts: [id],
      IDsContactUsersContacts: selectedContacts.map(
        (contact) => contact.id_contact,
      ),
    }
    const res = await ContactService.createUserContact(data)
    if (res.status === 201) {
      toast.success('Contatos atribuidos com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
      setUpdate(!update)
    } else {
      // addToast({
      //   type: 'error',
      //   title: 'Erro ao cadastrar contatos',
      //   description: 'Ocorreu um erro ao cadastrar os contatos',
      // })
      toast.error('Erro ao atribuir contatos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      })
    }
    handleCloseDialog()
  }

  return (
    <>
      <Dialog
        open={open}
        onChangeOpen={onChangeOpen}
        showIconClose={showIconClose}
      >
        <Container>
          <Header>
            <Title>Contatos</Title>
            <Subtitle>Lista de contatos cadastrados</Subtitle>
          </Header>
          <ListContacts>
            <DivRowSupContacts>
              <Input
                value={searchContact}
                onChange={(e) => setSearchContact(e.target.value)}
                placeholder="Pesquisar contato"
              />
            </DivRowSupContacts>
            {filteredContactsByNameOrNumber().length === 0 ? (
              <NoContacts>Nenhum contato encontrado</NoContacts>
            ) : (
              filteredContactsByNameOrNumber().map((contact) => (
                <Contact
                  key={contact.id_contact}
                  onClick={() => handleSelectContact(contact)}
                  $isSelected={verifySelectedContact(contact)}
                >
                  <DivRowContact>
                    <ContactText>{contact.full_name_contact}</ContactText>
                    {verifySelectedContact(contact) && <XCircle />}
                  </DivRowContact>
                </Contact>
              ))
            )}
          </ListContacts>
          <DivButton>
            <ButtonCancel onClick={handleCloseDialog}>Cancelar</ButtonCancel>
            <ButtonCadastro onClick={() => setOpenDialog(true)}>
              Cadastrar
            </ButtonCadastro>
            <Button onClick={() => handleSave()}>Salvar</Button>
          </DivButton>
        </Container>
      </Dialog>
      <DialogCadastro
        open={openDialog}
        onChangeOpen={setOpenDialog}
        update={updateContacts}
        setUpdate={setUpdateContacts}
      />
    </>
  )
}

export default DialogContacts
