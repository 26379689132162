import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const DialogContainer = styled.div`
  background-color: white;
  padding: 2rem 3rem 2rem 2rem;
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 35rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Text};
    &:hover {
      color: ${({ theme }) => theme.colors.TextHover};
    }
  }

  @media (max-width: 800px) {
    width: 90vw;
  }

  &.midia-gallery {
    width: 30vw;
    @media (max-width: 1580px) {
      width: 40vw;
    }

    @media (max-width: 1190px) {
      width: 50vw;
    }

    @media (max-width: 950px) {
      width: 60vw;
    }

    @media (max-width: 790px) {
      width: 70vw;
    }

    @media (max-width: 675px) {
      width: 80vw;
    }

    @media (max-width: 595px) {
      width: 90vw;
    }
  }
`

export const DialogOverlay = styled.div`
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 900;
  pointer-events: auto;
`
