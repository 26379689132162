import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
`

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.colors.guto.Azul3};
  color: ${({ theme }) => theme.colors.Text};
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  transition: 0.3s all;

  &:hover {
    color: ${({ theme }) => theme.colors.TextHover};
  }
`

export const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
  border: 2px dashed ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap; /* Mantém as imagens em linha */
`

export const ImageContainer = styled.div`
  display: inline-flex; /* Usa inline-flex para permitir overflow-x */
  align-items: center;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`

export const UploadButton = styled.button`
  background: ${({ theme }) => theme.colors.guto.Azul1};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }

  &.send {
    margin-right: 10px;
  }
`

export const ModalImage = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  max-width: 150px; /* Ajuste para o tamanho desejado */
  max-height: 150px;
  margin-right: 10px; /* Espaçamento entre as imagens */
  object-fit: contain;
`

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
