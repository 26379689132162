import imageContato from '../assets/images/imageContato'

export const format = async (lateral, getImage) => {
  const array = await Promise.all(
    lateral.map(async (element) => {
      const data = new Date(element.send_date_message)
      const dataAtual = new Date()
      let formattedDate

      if (dataAtual.getDate() - data.getDate() > 1) {
        formattedDate = `${data.getDate().toString().padStart(2, '0')}/${(
          data.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${data.getFullYear()}`
      } else {
        const hours = data.getHours().toString().padStart(2, '0')
        const minutes = data.getMinutes().toString().padStart(2, '0')
        formattedDate = `${hours}:${minutes}`
      }

      // const trueImage = imageContato
      let trueImage
      if (
        element.picture_filename_contact === null ||
        element.picture_filetype_contact === null
      ) {
        trueImage = imageContato
      } else {
        try {
          trueImage = await getImage(
            element.picture_filename_contact,
            element.picture_filetype_contact,
          )
        } catch {
          trueImage = imageContato
        }
      }

      return {
        id: element.id_contact,
        name: element.full_name_contact,
        image: trueImage,
        lastMessage:
          element.type_message === 'sticker' ? '' : element.content_message,
        lastMessageId: element.id_message,
        typeMessage: element.type_message,
        time: formattedDate,
        isViewedMessage: element.is_viewed_message,
        number: element.id_contact.split('@')[0],
        lastMessageTimestamp: new Date(element.send_date_message),
        unreadMessageCount: parseInt(element.unread_message_count),
        tags: element.tags,
        isArchivedContact: element.is_archived_contact,
        pageNumber: element.page_number,
        gradeContact: element.grade_contact,
        summaryContact: element.summary_contact,
      }
    }),
  )

  return array.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp)
}

export const getMessages = (messages) => {
  messages.sort(
    (a, b) => new Date(a.send_date_message) - new Date(b.send_date_message),
  )
  return messages.map((element) => ({
    idMessage: element.id_message,
    idReplyMessage: element.id_reply_message,
    isMine: element.from_contact_message === null,
    message: formatMessage(element.content_message),
    replyMessage: formatMessage(element.content_reply_message),
    fileName: element.file_name_message,
    fileType: element.file_type_message,
    fileNameReply: element.file_name_reply_message,
    fileTypeReply: element.file_type_reply_message,
    sendDateMessage: element.send_date_message,
    sendDateReplyMessage: element.send_date_reply_message,
    isFlag: false,
    isNoteMessage: element.is_note_message,
    isNoteReplyMessage: element.is_note_reply_message,
    isDeletedMessage: element.is_deleted_message,
    isEditedMessage: element.is_edited_message,
    isViewedMessage: element.is_viewed_message,
    isViewedReplyMessage: element.is_viewed_reply_message,
    userName: extractUsername(element.content_message),
    isNote: element.is_note,
    senderUserId: element.sender_user_id_message,
    senderUserIdReply: element.sender_user_id_reply_message,
    fromContactMessage: element.from_contact_message,
    fromContactReplyMessage: element.from_contact_reply_message,
    toContactMessage: element.to_contact_message,
    toContactReplyMessage: element.to_contact_reply_message,
    typeMessage: element.type_message,
    typeReplyMessage: element.type_reply_message,
  }))
}

export const extractImage = (image) => {
  try {
    const partes = image?.split(',', 2)
    if (partes.length > 1) {
      return partes[1]
    }
    return null
  } catch (error) {
    return null
  }
}

export const formatLastMessageTimeStamp = (timestamp) => {
  const date = new Date(timestamp)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  if (new Date().getDate() === date.getDate()) {
    return `${hours}:${minutes}`
  } else if (yesterday.getDate() === date.getDate()) {
    return 'Ontem'
  } else {
    return `${String(date.getDate()).padStart(2, '0')}/${String(
      date.getMonth() + 1,
    ).padStart(2, '0')}`
  }
}

export function formatTimeSent(isoString) {
  const date = new Date(isoString)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  if (isNaN(hours) && isNaN(minutes)) {
    return 'agora mesmo'
  } else {
    return `${hours}:${minutes}`
  }
}

export const formatDate = (date) => {
  const newDate = new Date(date)
  const day = String(newDate.getDate()).padStart(2, '0')
  const month = String(newDate.getMonth() + 1).padStart(2, '0')
  return `${day}/${month}`
}

export const formatDateChat = (date) => {
  const newDate = new Date(date)
  const day = String(newDate.getDate()).padStart(2, '0')
  const month = String(newDate.getMonth() + 1).padStart(2, '0')
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  if (
    newDate.getDate() === today.getDate() &&
    newDate.getMonth() === today.getMonth() &&
    newDate.getFullYear() === today.getFullYear()
  ) {
    return 'Hoje'
  } else if (
    newDate.getDate() === yesterday.getDate() &&
    newDate.getMonth() === yesterday.getMonth() &&
    newDate.getFullYear() === yesterday.getFullYear()
  ) {
    return 'Ontem'
  } else {
    return `${day}/${month}`
  }
}

export const extractUsername = (model) => {
  const regex = /\(([^)]+) \u{1F464}\)/u
  const match = regex.exec(model)
  if (match) {
    return match[1]
  } else {
    return null
  }
}

export const formatMessage = (message) => {
  if (message === null) return null
  if (message?.includes('👤)*')) {
    return message.split('👤)*')[1]
  }
  return message
}
export const formatTime = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const getFileType = (file) => {
  if (!file) {
    return 'chat'
  }
  const filetype = file.split(';')[0].split(':')[1].split('/')[0]
  if (filetype === 'application' || filetype === 'text') return 'document'
  return filetype
}

export function formatTimeSentWithDate() {
  const newDate = new Date()
  const hours = String(newDate.getHours()).padStart(2, '0')
  const minutes = String(newDate.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '')

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

function getLuminance(hex) {
  const { r, g, b } = hexToRgb(hex)
  const a = [r, g, b].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export function getTextColor(hex) {
  const luminance = getLuminance(hex)

  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}

export function countCharacters(array) {
  return array.reduce((acc, tag) => acc + tag.abbreviation_tag.length, 0)
}

export function countTagsLessThan25(array) {
  let count = 0
  const tags = []
  for (let i = 0; i < array.length; i++) {
    if (count + array[i].abbreviation_tag.length < 25) {
      count += array[i].abbreviation_tag.length
      tags.push(array[i])
    } else {
      break
    }
  }
  return tags
}

export function differenceInArray(array1, array2) {
  return array1.filter((element) => !array2.includes(element))
}

export function formatPhoneNumber(phone) {
  // eslint-disable-next-line
  let formatted = phone.replace(/[+\-\(\)\s]/g, '')

  if (formatted.length === 13 && formatted[4] === '9') {
    formatted = formatted.slice(0, 4) + formatted.slice(5)
  }

  return formatted.concat('@c.us')
}

export function formatPhoneNumber2(id) {
  // Remove o sufixo "@c.us" do ID
  const phoneNumber = id.replace('@c.us', '')

  // Adiciona o prefixo do Brasil +55 e formata o número
  const formattedPhoneNumber = `+55 (${phoneNumber.substring(0, 2)}) 9 ${phoneNumber.substring(2, 6)}-${phoneNumber.substring(6)}`

  return formattedPhoneNumber
}

export function getFirstNChars(str, n) {
  if (typeof str !== 'string') {
    throw new Error('O primeiro argumento deve ser uma string')
  }

  if (typeof n !== 'number' || n < 0) {
    throw new Error('O segundo argumento deve ser um número positivo')
  }

  const strSliced = str.slice(0, n)

  const plainText = strSliced.replace(/[*_~`]+/g, '')

  return plainText
}

export function formatDateWithYear(timestamp) {
  const date = new Date(timestamp)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export function formatDate2(timestamp) {
  const date = new Date(timestamp)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export function formatTimeSchedule(timestamp) {
  const date = new Date(timestamp)
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${hours}:${minutes}`
}

export function formatTextTrigger(arrayNames) {
  const names = arrayNames.map((name) => name.label).join(', ')
  if (names.length > 45) {
    return `${names.slice(0, 45)}...`
  }
  return names
}

export const processMessage = (text, linkDisable) => {
  if (!text) return text

  text = text.replace(/^\n+/, '')

  const urlRegex =
    /\b((https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)\b/g
  const boldPattern = /\*([^*]+?)\*/g
  const italicPattern = /_([^_]+?)_/g
  const strikethroughPattern = /~([^~]+?)~/g
  const monospacePattern = /```([\s\S]+?)```|`([^`]+?)`/g

  const processedText = text
    .replace(/\n/g, '<br />')
    .replace(urlRegex, (url) => {
      const href = url.startsWith('http') ? url : `http://${url}`
      return linkDisable
        ? url
        : `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`
    })
    .replace(boldPattern, (_, boldText) => `<strong>${boldText}</strong>`)
    .replace(italicPattern, (_, italicText) => `<em>${italicText}</em>`)
    .replace(
      strikethroughPattern,
      (_, strikethroughText) => `<s>${strikethroughText}</s>`,
    )
    .replace(monospacePattern, (_, blockText, inlineText) => {
      if (blockText) {
        return `<pre>${blockText}</pre>`
      }
      return `<code>${inlineText}</code>`
    })

  return <span dangerouslySetInnerHTML={{ __html: processedText }} />
}

export const formatarDataParaBrasil = (dataISO) => {
  const data = new Date(dataISO)

  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = String(data.getFullYear()).slice(2)
  const horas = String(data.getHours()).padStart(2, '0')
  const minutos = String(data.getMinutes()).padStart(2, '0')

  return `${dia}/${mes}/${ano}, às ${horas}:${minutos}`
}

export const isDateGreaterThanToday = (date) => {
  const today = new Date()
  return new Date(date) > today
}

export const formatGradeMessage = (msg) => {
  const regex = /Nota: (\d+)/
  const match = msg.match(regex)
  return match ? parseInt(match[1], 10) : null
}
